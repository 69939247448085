import React from 'react';
import Location from './Location';
import ContactInfo from './ContactInfo';

const Contact = () => {
    return (
        <div>
            <Location/>
            <ContactInfo/>
        </div>
    )
}

export default Contact