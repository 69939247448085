import React, {useState, useEffect} from 'react';
import Accordion from '../../components/commons/Accordion';
import { FaWhatsapp } from 'react-icons/fa';
import { MdOutlineMailOutline } from "react-icons/md";
import { apiFunctions, urlAPI } from '../../services/api.services';

const FaqDetail = () => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  const [faqVal, setFaq] = useState([]);

  useEffect(()=>{
    apiFunctions.faq((data) => {
    // console.log(data.data);
        const groupedData = data.data.reduce((acc, currentItem) => {
            if (!acc[currentItem.type]) {
            acc[currentItem.type] = [];
            }
            acc[currentItem.type].push(currentItem);
            return acc;
        }, {});
        setFaq(groupedData);
    });
  }, [])

  const [contactVal, setContact] = useState([]);

  useEffect(()=>{
    apiFunctions.contact((data) => {
        // console.log(data);
        setContact(data.data);
    });
  }, [])

  return (
    <div className='pb-14 border-b-[1px]'>
        <div className='max-w-[1240px] mx-auto pt-24 pb-10 px-4 text-center'>
            <div className='alegreya-sans-light text-lg'>
                <h1 className='font-normal amiri-regular'>Frequently Asked Questions</h1>
                <span className='alegreya-sans-light text-base'>More questions? Contact us</span>
                <div className='m-auto justify-beetween flex w-16 border-b-[1px] py-4'>
                    <MdOutlineMailOutline className='icon mr-1'/>
                    <a href={contactVal.whatsapp}><FaWhatsapp className='icon ml-1'/></a>
                </div>
            </div>
        </div>
        {faqVal.shipping_order && (
        <div className='amiri-regular max-w-[1240px] md:max-w-[740px] mx-auto px-4'>
            <h2 className='font-normal pb-4'>Shipping & Order Status</h2>
            {faqVal.shipping_order.map((item, index) => (
            <Accordion 
                key={index}
                title={item.question} 
                answer={<div dangerouslySetInnerHTML={{ __html: item.answer }} />}
            />
            ))}
        </div>
        )}
        {faqVal.payment && (
        <div className='amiri-regular max-w-[1240px] md:max-w-[740px] mx-auto px-4 py-10'>
            <h2 className='font-normal pb-4'>Payment</h2>
            {faqVal.payment.map((item, index) => (
            <Accordion 
                key={index}
                title={item.question}
                answer={<div dangerouslySetInnerHTML={{ __html: item.answer }} />}
            />
            ))}
        </div>
        )}
        {faqVal.about && (
        <div className='amiri-regular max-w-[1240px] md:max-w-[740px] mx-auto px-4 py-10'>
            <h2 className='font-normal pb-4'>About BatikOrganik</h2>
            {faqVal.about.map((item, index) => (
            <Accordion
                key={index}
                title={item.question}
                answer={<div dangerouslySetInnerHTML={{ __html: item.answer }} />}
            />
            ))}
        </div>
        )}
        {faqVal.product && (
        <div className='amiri-regular max-w-[1240px] md:max-w-[740px] mx-auto px-4 py-10'>
            <h2 className='font-normal pb-4'>Products</h2>
            {faqVal.product.map((item, index) => (
            <Accordion
                key={index}
                title={item.question}
                answer={<div dangerouslySetInnerHTML={{ __html: item.answer }} />}
            />
            ))}
        </div>
        )}
        {faqVal.refund && (
        <div className='amiri-regular max-w-[1240px] md:max-w-[740px] mx-auto px-4 py-10'>
            <h2 className='font-normal pb-4'>Returns and Exchanges</h2>
            {faqVal.refund.map((item, index) => (
            <Accordion
                key={index}
                title={item.question}
                answer={<div dangerouslySetInnerHTML={{ __html: item.answer }} />}
            />
            ))}
        </div>
        )}
    </div>
  );
};

export default FaqDetail;
