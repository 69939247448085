import React, {useState, useEffect} from 'react';
import "../../assets/css/singlePost.css";
import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';
import { useParams } from 'react-router-dom';

export default function Campaign() {
    const { id } = useParams();

    const [campaignVal, setCampaign] = useState([]);

    useEffect(()=>{
        apiFunctions.campaign((data) => {
            // console.log(data);
            setCampaign(data.data);
        });
    }, [])

    const post = campaignVal[id]; // Retrieve the post based on the ID from the URL params

    if (!post) {
        return <div>Loading...</div>; // Handle case when post is not found
    }

    const formatDate = (dateString) => {
        const months = [
          'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
          'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
        ];
      
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day} ${months[monthIndex]} ${year}`;
        return formattedDate;
      };
  return (
    <div className="singlePost border-b-[1px] md:p-20">
        <img
            className="w-full rounded-md"
            src={urlAPI+ post.image_url}
            alt=""
        />
      <div className="singlePostWrapper container mx-auto px-4">
        <h1 className="singlePostTitle pb-10">
          {post.title}
        </h1>
        <div className="singlePostInfo">
          <span>
            {/* By:
            <b className="singlePostAuthor">{post.admin}</b> */}
          </span>
          <span>{formatDate(post.created_at)}</span>
        </div>
        <div className='singlePostDesc text-justify' dangerouslySetInnerHTML={{ __html: post.description }} />
        
      </div>
    </div>
  );
}