function importAll(r) {
    let images = {};
    r.keys().forEach((key) => {
      images[key] = r(key);
    });
    return images;
  }
  
  const fetchImages = (folderPath) => {
    try {
      const images = importAll(require.context(`../assets/${folderPath}`, false, /\.(png|jpe?g|svg)$/));
      const imageUrls = Object.values(images).map((image) => image.default);
      return imageUrls;
    } catch (error) {
      console.error('Error fetching images:', error);
      return [];
    }
  };
  
  export  {fetchImages, importAll};
  