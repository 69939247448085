import React from 'react';
import Journey from './Journey';

const About = () => {
    return (
        <div>
            <Journey/>
        </div>
    )
}

export default About