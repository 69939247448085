import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Parallax } from 'react-parallax';
import { apiFunctions, urlAPI } from '../../services/api.services';

const BatikComp = () => {
  const [aboutBatikVal, setaboutBatik] = useState([]);

  useEffect(()=>{
    
    apiFunctions.aboutBatik((data) => {
      // console.log(data.data);
      setaboutBatik(data.data);
    });
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {aboutBatikVal.length >= 1 &&
      <div className='border-b-[1px]'>
        <div className='md:flex pt-20 md:pt-28 text-center'>
          <div className='md:w-3/6'>
            <p className='alegreya-sans-light uppercase text-sm pb-1'>{aboutBatikVal[0].subtitle}</p>
            <h1 className='amiri-regular uppercase tracking-widest'>{aboutBatikVal[0].title}</h1>
            <div className='px-5 amiri-regular text-xl md:max-w-[550px] m-auto'>
              <div className='pt-8 pb-10' dangerouslySetInnerHTML={{ __html: aboutBatikVal[0].caption }} />
            </div>
            <img className='md:img-layout mx-auto' src={urlAPI+aboutBatikVal[0].image_url} alt="" />
          </div>
          <div className='pt-10 md:pt-0 md:w-3/6'>
            <img className='md:img-layout2 mx-auto' src={urlAPI+aboutBatikVal[1].image_url} alt="" />
            {/* <p className='alegreya-sans-light uppercase text-sm pb-1 pt-10'>FARM TO CLOSET</p> */}
            <h1 className='amiri-regular uppercase tracking-widest pt-10'>{aboutBatikVal[1].title}</h1>
            <div className='px-5 amiri-regular text-xl md:max-w-[550px] m-auto'>
              <div className='pt-8 pb-10' dangerouslySetInnerHTML={{ __html: aboutBatikVal[1].caption }} />
            </div>
            {/* <a href={homepageVal[4].link}><button>{homepageVal[4].button_label}</button></a> */}
          </div>
        </div>
        <div className='py-10 lg:py-16'>
          <Parallax className='relative h-screen bg-cover' bgImage={urlAPI+aboutBatikVal[2].image_url} strength={200}>
            
          </Parallax>
        </div>

        <div className='max-w-[1240px] mx-auto py-10 lg:py-24 px-4 text-center'>
          <div className='alegreya-sans-light text-lg'>
            <p>{aboutBatikVal[3].title}</p>
            <h2 className='font-normal amiri-regular'>{aboutBatikVal[3].subtitle}</h2>
          </div>
        </div>

        <div className='lg:flex md:max-w-7xl m-auto gap-10 lg:gap-20 mx-4'>
          <div className='alegreya-sans-light text-lg text-center w-full lg:w-1/3'>
            <img src={urlAPI+aboutBatikVal[4].image_url} className='w-64 h-64 object-cover m-auto' alt="" />
            <h2 className='font-normal amiri-regular pt-4'>{aboutBatikVal[4].title}</h2>
            <p className='py-10 text-xl text-justify md:max-w-6xl m-auto' dangerouslySetInnerHTML={{ __html: aboutBatikVal[4].caption }} />
          </div>
          <div className='alegreya-sans-light text-lg text-center w-full lg:w-1/3'>
            <img src={urlAPI+aboutBatikVal[5].image_url} className='w-64 h-64 object-cover m-auto' alt="" />
            <h2 className='font-normal amiri-regular pt-4'>{aboutBatikVal[5].title}</h2>
            <p className='py-10 text-xl text-justify md:max-w-6xl m-auto' dangerouslySetInnerHTML={{ __html: aboutBatikVal[5].caption }} />
          </div>
          <div className='alegreya-sans-light text-lg text-center w-full lg:w-1/3'>
            <img src={urlAPI+aboutBatikVal[6].image_url} className='w-64 h-64 object-cover m-auto' alt="" />
            <h2 className='font-normal amiri-regular pt-4'>{aboutBatikVal[6].title}</h2>
            <p className='py-10 text-xl text-justify md:max-w-6xl m-auto' dangerouslySetInnerHTML={{ __html: aboutBatikVal[6].caption }} />
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default BatikComp;
