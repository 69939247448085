import React, { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';

const PartnerHeader = () => {
  const [partnershipVal, setPartnership] = useState([]);

  useEffect(()=>{
    apiFunctions.partnership((data) => {
      setPartnership(data.data);
    });
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {partnershipVal.length >= 1 &&
        <div className='w-auto md:h-screen relative'>
          <img
            className='w-full h-full object-cover'
            src={urlAPI+partnershipVal[0].image_url}
          />
          <div className='absolute w-full h-full top-0 left-0 bg-gray-900/40'></div>
          <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4 amiri-regular'>
            <h1 className='font-normal pb-5'>{partnershipVal[0].title}</h1>
            <div className='max-w-96 mx-auto'>
              <div className='md:text-lg'>{partnershipVal[0].subtitle}</div>
              {/* <div className='text-2xl' dangerouslySetInnerHTML={{ __html: galleryVal[0].caption }} /> */}
            </div>
          </div>
          
        </div>
      }
    </div>
  );
};

export default PartnerHeader;
