import React from 'react';
import Destinations from './Destinations';
import Hero from './Hero';
import BannerCTA from './BannerCTA';

const Home = () => {
    return (
        <div>
            <Hero />
            <Destinations />
            <BannerCTA />
        </div>
    )
}

export default Home