import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';

const Journey = () => {
  const [journeyVal, setJourney] = useState([]);

  useEffect(()=>{
    apiFunctions.journey((data) => {
      setJourney(data.data);
    });
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {journeyVal.length >= 1 &&
      <div>
        <div className='w-full relative md:flex p-8 md:py-24 md:px-24'>
          <div className='md:w-3/6 amiri-regular my-auto pt-8 md:pt-0'>
            <h1 className='font-normal py-10 max-w-[500px]'>{journeyVal[0].title}</h1>
            <a href={journeyVal[0].link}><button>From Our Founder</button></a>
          </div>
          <div className='md:w-5/6 amiri-regular my-auto pt-8 md:pt-0'>
            <p className='py-10 text-xl text-justify' dangerouslySetInnerHTML={{ __html: journeyVal[0].caption }} />
          </div>
        </div>
        {/* https://www.youtube.com/embed/Pi-xBmbZ6G4?si=r_z0NMsI5QuFit52 */}
        <div className='w-full h-48 md:h-48N relative'>
          <iframe width="100%" height="100%" src={journeyVal[1].link+`&autoplay=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        <div className='w-full relative md:flex p-8 md:py-24 md:px-24'>
          <div className='md:w-3/6 amiri-regular my-auto pt-8 md:pt-0'>
            <div className='alegreya-sans-light text-lg'>
              <p>{journeyVal[2].subtitle}</p>
              <h2 className='font-normal amiri-regular'>{journeyVal[2].title}</h2>
            </div>
            <p className='py-10 text-xl text-justify max-w-[500px]' dangerouslySetInnerHTML={{ __html: journeyVal[2].caption }} />
          </div>
          <div className='md:w-3/6 amiri-regular my-auto pt-8 md:pt-0'>
            <img
              className='w-full h-48 md:h-48N object-cover'
              src={urlAPI+journeyVal[2].image_url}
            />
          </div>
        </div>
        <div className='w-full h-48 md:h-48N relative'>
          <iframe width="100%" height="100%" src={journeyVal[3].link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        
        <div className='w-full relative md:flex p-8 md:py-24 md:px-24'>
          <div className='md:w-3/6 amiri-regular my-auto pt-8 md:pt-0'>
            <img
              className='w-full h-48 md:h-48N object-cover'
              src={urlAPI+journeyVal[4].image_url}
            />
          </div>
          <div className='md:w-3/6 amiri-regular my-auto pt-8 md:pl-10 md:pt-0'>
            <div className='alegreya-sans-light text-lg'>
              <p>{journeyVal[4].subtitle}</p>
              <h2 className='font-normal amiri-regular'>{journeyVal[4].title}</h2>
            </div>
            <p className='py-10 text-xl text-justify max-w-[500px]' dangerouslySetInnerHTML={{ __html: journeyVal[4].caption }} />
          </div>
        </div>
        <div className='w-full h-48 md:h-48N relative'>
          <iframe width="100%" height="100%" src={journeyVal[5].link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        
      </div>
      }
    </div>
  );
};

export default Journey;
