import React, { useEffect, useState }  from 'react';

import { apiFunctions, urlAPI } from '../../services/api.services';

const Location = () => {
  const [contactVal, setContact] = useState([]);
  const [storeVal, setStore] = useState([]);

  useEffect(()=>{
    apiFunctions.contact((data) => {
      setContact(data.data);
    });
    apiFunctions.store((data) => {
      setStore(data.data);
    });
  }, [])

  return (
    <div>
      <div className='w-full h-screen relative'>
        <img
          className='w-full h-full object-cover'
          src={urlAPI+contactVal.banner_img}
        />
        <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
        
      </div>
      <div className='max-w-[1240px] mx-auto pt-16 pb-28 px-4 text-center'>
        <div className='md:flex pt-10'>
          <div className='lg:pl-20 md:w-2/6 md:text-justify'>
            <h1 className='amiri-regular uppercase tracking-widest'>Location</h1>
            <div className='py-10 md:py-20 alegreya-sans-regular text-sm md:text-justify'>
              <p className='md:pb-0 alegreya-sans-medium'>{contactVal.street}</p>
              <p className='alegreya-sans-light'>{contactVal.city}</p>
            </div>
            <a href={contactVal.cta_link}><button className='bg-transparent text-black border-black hover:bg-inherit'>{contactVal.cta_label}</button></a>
          </div>
          <div className='pt-10 md:pt-0 md:w-4/6'>
            {/* <div className='font-light' dangerouslySetInnerHTML={{ __html: galleryVal[25].caption }} /> */}
            <div className='pb-5 alegreya-sans-light text-lg md:text-justify'>
              <p className='pb-10' dangerouslySetInnerHTML={{ __html: contactVal.caption_contact }} />
            </div>
            <div className='mapDiv' dangerouslySetInnerHTML={{ __html: contactVal.map_iframe }} />
            
          </div>
        </div>
      </div>
      <div>
        <div>
          <h1 className='amiri-regular uppercase tracking-widest text-center'>Offline Store</h1>
        </div>
        <div className='py-14'>
          <div className='flex m-auto gap-5 lg:gap-x-24 flex-wrap md:pb-12'>
            {storeVal.map((item) => (
            <div className='w-3/4 md:w-1/4 mx-auto'>
              <p className='text-lg alegreya-sans-medium text-center pt-2 pb-4'>{item.name}</p>
              <p className='amiri-regular text-center' dangerouslySetInnerHTML={{ __html: item.address }} />
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
