import React from 'react';
import FaqDetail from './FaqDetail';

const Faq = () => {
    return (
        <div>
            <FaqDetail/>
        </div>
    )
}

export default Faq