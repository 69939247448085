import React from 'react';
import BatikComp from './BatikComp';

const BatikAbout = () => {
    return (
        <div>
            <BatikComp/>
        </div>
    )
}

export default BatikAbout