import React, { useEffect, useState } from 'react'
import { apiFunctions, urlAPI } from '../../services/api.services';

import { Slider, SliderBox, SliderMockup } from '../../components/commons/Swiper';
import { importAll } from '../../services/fetchImages';
import Modal from '../../components/commons/Modal';
import PartnerHeader from './PartnerHeader';
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const PartnerCorpo = () => {
  const [partnershipVal, setPartnership] = useState([]);
  const [partnershipLogo, setLogoPartnership] = useState([]);

  const [contactVal, setContact] = useState([]);
  const [uniformsVal, setUniforms] = useState([]);
  const [socialVal, setSocial] = useState(null);
  const icons = {
    instagram: FaInstagram,
    tiktok: FaTiktok,
    youtube: FaYoutube,
    facebook: FaFacebook,
    whatsapp: FaWhatsapp,
  };

  useEffect(()=>{
    apiFunctions.partnership((data) => {
      setPartnership(data.data);
      setLogoPartnership(data.partnership_images);
    });
    apiFunctions.uniforms((data) => {
      setUniforms(data.data);
      setSocial(data.social)
    });
    apiFunctions.contact((data) => {
      setContact(data.data);
    });
    // for Carousel
  }, [])
  // for Carousel api example

  // const slidesMockup = Array.from({ length: 5 }, () => ImgExample)
  const slidesMockup = uniformsVal.filter((item) => item.section_part === '3').map((item) => item);
  const slidesLogo = partnershipLogo.map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);

  return (
    <div className='border-b-[1px]'>
    {/* <PartnerHeader/> */}
    <div className='mb-20'></div>
    {uniformsVal.length >= 1 &&
      <div className='mx-auto text-center'>
        {/* konten 1 */}
        <div className='py-10 md:py-16 lg:py-20'>
          <h1 className='amiri-regular tracking-wide max-w-[54rem] m-auto'>{uniformsVal[0].title}</h1> 
          
          <div className='max-w-[45rem] m-auto px-5 amiri-regular text-xl lg:text-xl'>
            <div className='pt-6 text-justify' dangerouslySetInnerHTML={{ __html: uniformsVal[0].caption }} />
          </div>
          {/* <Slider slideUrls={slides1} /> */}
        </div>

        {/* konten 2 */}
        <div className='text-center bg-black/10 py-16'>
          <div className='amiri-regular pb-10'>
            <h1 className='font-normal text-black/70'>{partnershipVal.length > 0 ? partnershipVal[5].title : null}</h1>
          </div>
          <div className='mx-auto px-10 swiper-logo'>
            <SliderBox slideUrls={slidesLogo} />
          </div>
        </div>
        
        {/* konten 3 */}
        <div className='py-10 md:py-16 lg:py-20'>
          <h1 className='amiri-regular tracking-wide'>{uniformsVal[1].title}</h1>
          <p className='amiri-regular py-1 lg:text-lg' dangerouslySetInnerHTML={{ __html: uniformsVal[1].caption }} />

          <SliderMockup slideUrls={slidesMockup} />

          <a href={uniformsVal[1].link}><button className='my-5'>{uniformsVal[1].button_label}</button></a>
        </div>

        {/* konten 4 */}
        <div className='py-10 md:py-16'>
          <div>
            <h1 className='amiri-regular uppercase tracking-widest text-center'>Social Media</h1>
          </div>
          <div className='py-10'>
            <div className='flex m-auto gap-5 lg:gap-x-24 flex-wrap md:w-3/4 lg:w-1/2'>
              {socialVal.map((platform) => {
                const contactUrl = contactVal[platform];
                const IconComponent = icons[platform];
                return (
                  contactUrl !== "0" && (
                    <a key={platform} href={contactUrl} target='_blank' className='m-auto'>
                      <IconComponent className='lg:text-gray-400 hover:text-black text-2xl lg:text-3xl xl:text-4xl' />
                    </a>
                  )
                );
              })}
            </div>
          </div>
        </div>
        
      </div>
    }
    </div>
  )
} 

export default PartnerCorpo