import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'; 

import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';
import Accordion from '../../components/commons/Accordion';

const Collection = () => {
  const { id } = useParams();
  const [collectVal, setCollect] = useState([]);
  const [catVal, setCat] = useState([]);

  useEffect(()=>{
    // collection_category_id = id -> in category
    apiFunctions.collection((data) => {
        const groupedData = data.data.reduce((acc, currentItem) => {
            if (!acc[currentItem.collection_category_id]) {
            acc[currentItem.collection_category_id] = [];
            }
            acc[currentItem.collection_category_id].push(currentItem);
            return acc;
        }, {});
      setCollect(groupedData);
    });
    apiFunctions.category((data) => {
      setCat(data.data);
    });
  }, [])

//   const filteredCategory = catVal.find((category) => category.id === id);
  const filteredCategory = catVal.find((category) => category.id === parseInt(id));
  const filteredCollection = collectVal[id] || [];

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {/* {collectVal.length >= 1 && */}
      <div className='border-b-[1px]'>
        {filteredCategory && (
        <>
        <div className='h-auto lg:h-screen w-full'>
          <img src={urlAPI+filteredCategory.cover_image_url} className='h-full m-auto object-cover' alt="" />
          <div className='absolute top-0 w-full h-[30%] md:h-[50%] lg:h-screen flex flex-col justify-center text-center text-white p-4'>
            <div className='bg-gray-900/30 w-fit m-auto pb-0 p-1 rounded'>
              <h1 className='tracking-widest amiri-regular uppercase text-white drop-shadow-xl"'>{filteredCategory.title}</h1>
            </div>
          </div>
        </div>
        <div className='max-w-[1240px] mx-auto py-5 px-4 text-left'>
          <div className='alegreya-sans-regular text-lg'>
            <div className='md:max-w-[45rem] m-auto' dangerouslySetInnerHTML={{ __html: filteredCategory.description}} />
          </div>
        </div>
        </>
        )}
        <div className='alegreya-sans-regular container m-auto pt-5 md:pt-10'>
          {filteredCollection.map((item, index) => (
            <div
              key={index}
              className={`lg:flex ${
                index % 2 === 0 ? "" : "md:flex-row-reverse"
              }`}
            >
              <div className="pt-5 md:pt-0 md:w-5/6 lg:w-3/6 m-auto">
                <img
                  className="mx-auto lg:h-72 xl:h-80 w-full object-cover"
                  src={urlAPI + item.image_url}
                  alt=""
                />
              </div>
              <div
                className={`m-auto text-left md:w-5/6 lg:w-3/6 my-auto py-5 lg:py-0 px-4 ${
                  index % 2 === 0
                    ? "lg:pl-8"
                    : "lg:pr-8"
                }`}
              >
                <h2 className="uppercase tracking-widest amiri-regular">
                  {item.name}
                </h2>
                <hr class={`h-1 w-10 bg-gray-200 dark:bg-gray-700`}/>
                <div
                  className="py-4 text-black lg:text-sm xl:text-base"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          ))}
        </div>
        
        
      </div>
      {/* } */}
    </div>
  );
};

export default Collection;
