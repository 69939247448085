export const links = [
  {
    name: "Our Collection",
    submenu: true,
    sublinks: [
      {
        Image: '/static/media/batiklayout1.3b3892f8afd09f2ca204.jpg',
        Head: "Batik",
        description:`Tetap Memelihara Tradisi dengan merangkul Modernitas
        Temukan sentuhan baru pada koleksi BatikOrganik, dengan cara inovatif menghadirkan karya batik cap, tulis, kombinasi dan juga koleksi tenun yang tak lagi sekadar warisan, tapi juga simbol gaya hidup. 
        Jelajahi koleksi BatikOrganik menjadi warisan budaya dengan nuansa modern dalam setiap motif. 
        `,
        sublink: [
        ],
      },
      {
        Image: '/static/media/batiklayout1.3b3892f8afd09f2ca204.jpg',
        Head: "Women",
        description:`Koleksi Wanita BatikOrganik yang menggabungkan keanggunan tradisional dengan gaya modern, 
        lengkapi gaya anda dengan sentuhan unik dari koleksi yang memadukan kekayaan budaya.
        `,
        sublink: [
        ],
      },
      {
        Image: '/static/media/batiklayout1.3b3892f8afd09f2ca204.jpg',
        Head: "Men",
        description:`Gabungan harmonis antara tradisi yang kuat dan gaya modern yang berkelas, 
        dan dapatkan tampilan yang memukau dengan sentuhan unik dari koleksi BatikOrganik yang 
        menggabungkan warisan budaya dengan estetika futuristik.
        `,
        sublink: [
        ],
      },
      {
        Image: '/static/media/batiklayout1.3b3892f8afd09f2ca204.jpg',
        Head: "Organic Fiber",
        description:`Untuk masa depan berkelanjutan, kami menghadirkan kehidupan baru pada seni batik, 
        untuk masa depan berkelanjutan dengan bahan baku organik acacia wood chip dan serat eucalyptus. 
        Merupakan sebuah janji BatikOrganik pada kelestarian lingkungan. 
        Temukan kesempurnaan dalam harmoni alam dan keindahan karya seni, 
        menggabungkan keindahan seni tradisional dengan nilai-nilai modern. 
        `,
        sublink: [
        ],
      },
      {
        Image: '/static/media/batiklayout1.3b3892f8afd09f2ca204.jpg',
        Head: "Craft",
        description:`Sentuhan Ajaib Kain Batik
        Karya seni kerajinan tangan yang bernilai spesial dengan kain batik memberikan keanggunan setiap karya. 
        Termasuk beberapa koleksi dari Perca, sebagai upaya mengurangi limbah tekstil BatikOrganik memiliki koleksi dekorasi rumah hingga aksesoris fashion, 
        temukan koleksi kerajinan tangan yang memadukan keindahan tradisi dengan sentuhan modern.
        `,
        sublink: [
        ],
      },
      {
        Image: '/static/media/batiklayout1.3b3892f8afd09f2ca204.jpg',
        Head: "Gift",
        description:`BatikOrganik juga menyediakan layanan pembuatan souvenir, gift, dan hampers untuk personal dan juga corporate. 
        Anda juga dapat menciptakan hadiah yang tidak terlupakan dengan layanan personalisasi motif dan desain bersama BatikOrganik. 
        Tunjukan kasih sayang dengan hadiah yang tercipta dari keindahan kain batik Indonesia dan keunikan desain milik Anda.
        `,
        sublink: [
        ],
      },
    ],
  },
  {
    name: "Communiqué",
    submenu: true,
    sublinks: [
      {
        Head: "About",
        sublink: [
          { name: "Journey", link: "/about" },
          { name: "Batik", link: "/about-batik" },
          { name: "Partnership", link: "/partnership" },
          { name: "Testimonial", link: "/review" }
        ],
      },
      {
        Head: "Connect",
        sublink: [
          { name: "Contact & Location", link: "/contact" },
          { name: "FAQ", link: "/faq" },
          { name: "Whatsapp", link: "https://api.whatsapp.com/send/?phone=6281617000350" }
        ],
      },
      {
        Head: "Campaign",
        sublink: [
          { name: "Campaign I", link: "/campaign/id" },
          { name: "Campaign II", link: "/campaign/id" },
          { name: "Campaign III", link: "/campaign/id" },
          { name: "Campaign IV", link: "/campaign/id" },
          { name: "Campaign V", link: "/campaign/id" },
          { name: "Campaign VI", link: "/campaign/id" },
        ],
      },
    ],
  },
];
