import React from 'react';
import Partnership from './Partnership';
import PartnerHeader from './PartnerHeader';

const Partner = () => {
    return (
        <div>
            <PartnerHeader/>
            <Partnership/>
        </div>
    )
}

export default Partner