import React, { useEffect, useState } from 'react'
import { apiFunctions, urlAPI } from '../../services/api.services';

import { SliderReview } from '../../components/commons/Swiper';
import { PostContactList } from '../../components/layout/Navbar/PostContact';

export const Review = () => {
  const [testiImg, setTestiImg] = useState([]);
  const [testiContent, setTestiContent] = useState(null);

  useEffect(()=>{
    apiFunctions.testimonial((data) => {
      setTestiImg(data.data);
      setTestiContent(data.setting);
    });
  }, [])

  const slides1 = testiImg.filter((slide) => slide.source !== 'google').map((item) => item.image_url && (urlAPI + item.image_url));

  return (
    <div className='border-b-[1px] bg-[#f3f3f3]'>
      <div className='max-w-[1240px] mx-auto px-4 pt-24 text-center'>
        {/* konten 1 */}
        {testiContent &&
        <div className='py-10 md:py-16 lg:py-20'>
          <p className='alegreya-sans-light text-sm pb-1'>{testiContent.title}</p>
          <h1 className='amiri-regular tracking-widest'>{testiContent.subtitle}</h1> 
          
          <div className='max-w-[54rem] m-auto md:px-5 amiri-regular lg:text-xl'>
            <div className='pt-6 pb-10' dangerouslySetInnerHTML={{ __html: testiContent.caption }} />
          </div>
          <SliderReview slideUrls={slides1} />
          <a href={testiContent.button_link}><button className='mt-4 mb-6'>{testiContent.button_label}</button></a>
        </div>
        }
      </div>

      <PostContactList/>
    </div>
  )
} 
