import React, { useEffect, useState } from 'react'
import { apiFunctions, urlAPI } from '../../services/api.services';

import { ReviewGallery, Slider, SliderReview } from '../../components/commons/Swiper';
import Modal from '../../components/commons/Modal';

const Galeri = () => {
  const [contactVal, setContact] = useState([]);
  const [storeVal, setStore] = useState([]);
  const [Content1, setContent1] = useState([]);
  const [Content2, setContent2] = useState([]);
  const [Content3, setContent3] = useState([]);
  const [Content4, setContent4] = useState([]);

  const [testiImg, setTestiImg] = useState([]);
  const [testiContent, setTestiContent] = useState(null);
  useEffect(()=>{
    apiFunctions.testimonial((data) => {
      setTestiImg(data.data);
      setTestiContent(data.setting);
    });
  }, [])

  const [openModal, setOpenModal] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const handleImageClick = (index) => {
    setOpenModal(true); // Open the modal
    setSelectedImageIndex(index); // Set the selected image index
  };

  useEffect(()=>{
    apiFunctions.gallery((data) => {
      const Gallery = data.data
      setContent1(Gallery.filter(item => item.section_part === '1'));
      setContent2(Gallery.filter(item => item.section_part === '2'));
      setContent3(Gallery.filter(item => item.section_part === '3'));
      setContent4(Gallery.filter(item => item.section_part === '4'));
    });
    apiFunctions.contact((data) => {
      setContact(data.data);
    });
    apiFunctions.store((data) => {
      setStore(data.data);
    });
  }, [])
  // for Carousel api example
  const slides1 = Content2.map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);
  const slidesTesti = testiImg.filter((item) => item.source === 'google').map((item) => item.image_url && (urlAPI + item.image_url));

  return (
    <div className='border-b-[1px]'>
      <div className='max-w-[1240px] mx-auto px-4 pt-24 text-center'>
        {/* konten 1 */}
        {Content1.length >= 1 &&
        <div className='py-10 md:py-16 lg:py-20'>
          <h1 className='amiri-regular tracking-widest'>{Content1[0].title}</h1> 
          
          <div className='max-w-[54rem] m-auto px-5 amiri-regular lg:text-xl'>
            <div className='pt-6 pb-10' dangerouslySetInnerHTML={{ __html: Content1[0].caption }} />
          </div>
          <Slider slideUrls={slides1} />
        </div>
        }
        
        {/* konten 3 */}
        {Content3.length >= 1 &&
        <div>
          <div className='md:flex lg:mx-10 justify-between gap-4 pb-5'>
            <div className='w-full pb-5'>
              <p className='text-lg alegreya-sans-medium pt-2 pb-4'>{Content3[0].title}</p>
              <div className='amiri-regular' dangerouslySetInnerHTML={{ __html: Content3[0].caption }} />
            </div>
            <div className='w-full pb-5'>
              <p className='text-lg alegreya-sans-medium pt-2 pb-4'>{Content3[1].title}</p>
              <div className='amiri-regular' dangerouslySetInnerHTML={{ __html: Content3[1].caption }} />
            </div>
          </div>
          <div className='mapDiv pb-10 md:w-5/6 mx-auto' dangerouslySetInnerHTML={{ __html: contactVal.map_iframe }} />
          <a href={Content3[2].link}><button className='mb-10'>{Content3[2].button_label}</button></a>
        </div>
        }

        {/* konten 4 */}
        {Content4.length >= 1 &&
        <div className='py-10 md:py-16'>
          <div className='md:w-10/12 grid grid-cols-2 md:grid-cols-3 gap-5 m-auto'>
            {Content4.map((item) => (
              <div key={item.id} className='cursor-pointer' onClick={() => handleImageClick(item.id)}>
                  {item && (
                  <img
                      src={urlAPI + item.image_url}
                      className='h-auto w-auto object-cover mx-auto rounded-md'
                  />
                  )}
              </div>
            ))}
            {Content4.filter(item => item.id === selectedImageIndex).map((item) => (
              <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <div className="w-[20rem] md:w-[27rem]">
                  <img src={urlAPI+item.image_url} alt="" className='rounded'/>
                </div>
              </Modal>
            ))}
          </div>
        </div>
        }

      </div>

      {/* konten 5 */}
      <div className='bg-[#f3f3f3]'>
        <div className='max-w-[1240px] mx-auto px-4 text-center'>
          {testiContent &&
          <div className='py-10 md:py-16 lg:py-10'>
            <p className='alegreya-sans-light text-sm pb-1'>{testiContent.title}</p>
            <h1 className='amiri-regular tracking-widest'>{testiContent.subtitle}</h1> 
            
            {/* <div className='max-w-[54rem] m-auto md:px-5 amiri-regular lg:text-xl'>
              <div className='pt-6 pb-10' dangerouslySetInnerHTML={{ __html: testiContent.caption }} />
            </div> */}
            {/* <SliderReview slideUrls={slidesTesti} /> */}
            <ReviewGallery slideUrls={slidesTesti} />
            {/* <a href={testiContent.button_link}><button className='mt-4 mb-6'>{testiContent.button_label}</button></a> */}
          </div>
          }
        </div>
      </div>

      {/* konten 6 */}
      <div className='py-10 md:py-16 max-w-[1240px] mx-auto px-4'>
        <div>
          <h1 className='amiri-regular uppercase tracking-widest text-center'>Offline Store</h1>
        </div>
        <div className='py-10'>
          <div className='flex m-auto gap-5 lg:gap-x-24 flex-wrap md:pb-12'>
            {storeVal.map((item) => (
            <div className='w-3/4 md:w-1/4 mx-auto'>
              <p className='text-lg alegreya-sans-medium text-center pt-2 pb-4'>{item.name}</p>
              <p className='amiri-regular text-center' dangerouslySetInnerHTML={{ __html: item.address }} />
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
} 

export default Galeri