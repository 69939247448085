import React, { useEffect, useState } from 'react';

import {
  FaFacebook,
  FaTwitter,
  FaInstagram, FaYoutube,
  FaTiktok, FaWhatsapp
} from 'react-icons/fa';

import { apiFunctions, urlAPI } from '../../../services/api.services';

const Footer = () => {
  const [contactVal, setContact] = useState([]);
  const [time, setTime] = useState(new Date());

  useEffect(()=>{
    apiFunctions.contact((data) => {
      // console.log(data);
      setContact(data.data);
    });
    const intervalId = setInterval(() => {
      setTime(new Date()); // Update current time every second
    }, 1000);
  }, [])

  return (
    <div>
      
      <div>
        <div className='w-full mx-auto flex flex-col p-4 md:p-14'>
          <div className='sm:flex text-center justify-between items-center'>
            <ul className='lg:flex'>
              <li className='md:text-justify'>
                <p className='text-lg alegreya-sans-regular'>Help</p>
                <div className='pt-2 alegreya-sans-light'>
                  <p className='pt-2'><a href="/faq">FAQ</a></p>
                  <p className='pt-2'><a href="/contact">Contact</a></p>
                  <p className='pt-2'><a href="/review">Testimonial</a></p>
                  <p className='pt-2'><a href={contactVal.whatsapp}>{contactVal.phone}</a></p>
                  <p className='pt-2'><a href={`mailto:${contactVal.email}`}>{contactVal.email}</a></p>
                </div>
              </li>
              <li className='md:text-justify'>
                <p className='text-lg alegreya-sans-regular'>About Us</p>
                <div className='pt-2 lg:w-2/4 alegreya-sans-light'>
                  <div dangerouslySetInnerHTML={{ __html: contactVal.about }} />
                </div>
              </li>
            </ul>
            <div className='justify-between w-full sm:max-w-[280px] my-4'>
              <img src={urlAPI+contactVal.logo_img} alt="" className='h-30 object-contain mx-auto'/>
              <img src={urlAPI+contactVal.partnership_imgs} alt="" className='h-20 object-contain mx-auto'/>
            </div>
          </div>
        </div>
        <div className='w-full bg-gray-100 mx-auto flex flex-col p-14'>
          <div className='sm:flex text-center justify-between items-center'>
            <p className='alegreya-sans-light text-sm'>&copy; {time.getFullYear()}, BatikOrganik</p>
            <div className='flex justify-between w-full md:justify-normal md:gap-10 md:w-auto sm:max-w-[280px] my-4'>
              {contactVal.instagram !== "0" && (
                <a href={contactVal.instagram}><FaInstagram className='icon' /></a>
              )}
              {contactVal.tiktok !== "0" && (
                <a href={contactVal.tiktok}><FaTiktok className='icon' /></a>
              )}
              {contactVal.twitter !== "0" && (
                <a href={contactVal.twitter}><FaTwitter className='icon' /></a>
              )}
              {contactVal.youtube !== "0" && (
                <a href={contactVal.youtube}><FaYoutube className='icon' /></a>
              )}
              {contactVal.facebook !== "0" && (
                <a href={contactVal.facebook}><FaFacebook className='icon' /></a>
              )}
              {contactVal.whatsapp !== "0" && (
                <a href={contactVal.whatsapp}><FaWhatsapp className='icon' /></a>
              )}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Footer;
