import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { apiFunctions,urlAPI } from '../../services/api.services';

const Hero = () => {
  const [homepageVal, setHomepage] = useState([]);

  useEffect(()=>{
    apiFunctions.homepage((data) => {
      // console.log(data.data);
      setHomepage(data.data);
    });
  }, [])

  return (
    <div className='w-full lg:h-screen relative'>
      {homepageVal.length >= 1 &&
      <img
        className='w-full h-full object-cover'
        src={urlAPI+homepageVal[0].image_url}
      />
      }
      {/* <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div> */}
    </div>
  );
};

export default Hero;
