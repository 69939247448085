import React from 'react';
import AllCollection from './AllCollection';

const AllCollectPage = () => {
    return (
        <div>
            <AllCollection/>
        </div>
    )
}

export default AllCollectPage