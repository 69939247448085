import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import React from 'react';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Gallery from './pages/Gallery/Gallery';
import Blog from './pages/Blog/Blog';
import { Login, Recover, Register } from './pages/Account/Account';
import Faq from './pages/Faq/Faq';
import SingleBlog from './pages/Blog/SingleBlog';
import About from './pages/About/About';
import Partner from './pages/Partnership/Partner';
import BatikAbout from './pages/AboutBatik/BatikAbout';
import CollectPage from './pages/Collection/CollectPage';
import AllCollectPage from './pages/Collection/AllCollectPage';
import PartnerForm from './pages/Partnership/PartnerForm';
import PartnerSelect from './pages/Partnership/PartnerSelect';
import PartnerSelectHampers from './pages/Partnership/PartnerSelectHampers';
import BatikMaps from './pages/MapBatik/BatikMaps';
import FloatingActionButtons from './components/commons/BtnFloat';
import Navbar from './components/layout/Navbar/Navbar';
import Footer from './components/layout/Navbar/Footer';
import GalleryReview from './pages/Gallery/GalleryReview';
import Campaign from './pages/Campaign';
import { Review } from './pages/Review';
import PartnerCorpo from './pages/Partnership/PartnerCorpo';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <FloatingActionButtons/>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/map-batik' element={<BatikMaps/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/about-batik' element={<BatikAbout/>} />
        <Route path='/partnership' element={<Partner/>} />
        <Route path='/partnership/build' element={<PartnerSelect/>} />
        <Route path='/partnership/build/:id' element={<PartnerSelect/>} />
        <Route path='/partnership/hampers' element={<PartnerSelectHampers/>} />
        <Route path='/partnership/hampers/:id' element={<PartnerSelectHampers/>} />
        <Route path='/partnership/custom' element={<PartnerForm/>} />
        <Route path='/partnership/corpo' element={<PartnerCorpo/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/gallery-review' element={<GalleryReview/>} />
        <Route path='/blog' element={<Blog/>} />
        <Route path='/blog/:id' element={<SingleBlog/>} />
        <Route path='/collection/:id' element={<CollectPage/>} />
        <Route path='/collection' element={<AllCollectPage/>} />
        <Route path='/faq' element={<Faq/>} />
        <Route path='/campaign/:id' element={<Campaign/>} />
        <Route path='/review' element={<Review/>} />
        <Route path='/admin' />
        <Route path='*' element={<NotFound/>} />
        {/* <Route path='/login' element={<Login/>} /> */}
        {/* <Route path='/register' element={<Register/>} />
        <Route path='/recoverPass' element={<Recover/>} /> */}
      </Routes>
      <Footer/>
    </Router>
  )
}

export default App;
