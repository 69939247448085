import React from 'react';
import MapsBatik from './MapsBatik';

const BatikMaps = () => {
    return (
        <div>
            <MapsBatik />
        </div>
    )
}

export default BatikMaps