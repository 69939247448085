import React, { useEffect, useState } from 'react'
import { apiFunctions, urlAPI } from '../../services/api.services';

import { Slider } from '../../components/commons/Swiper';

const Destinations = () => {
  const [homepageVal, setHomepage] = useState([]);

  // for Carousel
  
  const slides1 = homepageVal.filter((item) => item.section_part === '2' && item.type === 'carousel').map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);
  const slides2 = homepageVal.filter((item) => item.section_part === '3' && item.type === 'carousel').map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);
  const slides4 = homepageVal.filter((item) => item.section_part === '5' && item.type === 'carousel').map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);
  const slides6 = homepageVal.filter((item) => item.section_part === '7' && item.type === 'carousel').map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);
  // for Carousel api example

  useEffect(()=>{
    apiFunctions.homepage((data) => {
      // console.log(data.data);
      setHomepage(data.data);
    });
  }, [])

  return (
    <div>
      
    {homepageVal.length >= 1 &&
      <div className='max-w-[1240px] mx-auto px-4 text-center'>
        {/* konten 1 */}
        <div className='py-10 md:py-16 lg:py-20'>
          <p className='alegreya-sans-light text-sm pb-1'>{homepageVal[1].subtitle}</p>
          <h1 className='amiri-regular tracking-widest'>{homepageVal[1].title}</h1> 
          
          <div className='max-w-[54rem] m-auto px-5 amiri-regular lg:text-xl'>
            <div className='pt-6 pb-10' dangerouslySetInnerHTML={{ __html: homepageVal[1].caption }} />
          </div>
          <a href={homepageVal[1].link}><button className='mb-10'>{homepageVal[1].button_label}</button></a>

          <Slider slideUrls={slides1} />

          {/*video embed can be edit & if autoplay add => &autoplay=1 */}
          <div className='w-full h-48 md:h-96 lg:h-[40rem] relative mt-20'>
            <iframe className='rounded-md' width="100%" height="100%" src={`${homepageVal[2].link}&rel=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        
        {/* konten 2 */}
        <div className='py-10 md:py-16 lg:py-20'>
          <h1 className='amiri-regular tracking-widest'>{homepageVal[3].title}</h1> 
          
          <div className='max-w-[54rem] m-auto px-5 amiri-regular lg:text-xl'>
            <div className='pt-6 pb-10' dangerouslySetInnerHTML={{ __html: homepageVal[3].caption }} />
          </div>
          <div className='flex md:max-w-xl m-auto gap-5 md:gap-10 flex-wrap pb-10'>
            
          {homepageVal.filter((item) => item.section_part === '3' && item.type === 'sponsor').map((logoPartner) => (
            <div className='w-1/4 m-auto'>
              <img src={urlAPI+logoPartner.image_url} alt="" className='h-10 md:h-20 w-full object-contain'/>
            </div>
          ))}

          </div>
          <a href={homepageVal[3].link}><button className='mb-10'>{homepageVal[3].button_label}</button></a>
          
          <Slider slideUrls={slides2} />

        </div>

        {/* konten 3 -etnic */}
        <div className='md:flex py-10 md:py-16 lg:py-20'>
          <div className='md:w-3/6'>
            <p className='alegreya-sans-light text-sm pb-1'>{homepageVal[4].subtitle}</p>
            <h1 className='amiri-regular tracking-widest'>{homepageVal[4].title}</h1>
            <div className='px-5 amiri-regular lg:text-xl pt-8 pb-10'>
              <div dangerouslySetInnerHTML={{ __html: homepageVal[4].caption }} />
            </div>
            <img className='w-4/5 md:w-11/12 mx-auto rounded-md' src={urlAPI+homepageVal[4].image_url} alt="" />
          </div>
          <div className='pt-10 md:pt-0 md:w-3/6'>
            <img className='w-4/5 md:w-11/12 mx-auto rounded-md' src={urlAPI+homepageVal[5].image_url} alt="" />
            <div className='px-5 py-10 amiri-regular lg:text-xl'>
              <div className='pb-10' dangerouslySetInnerHTML={{ __html: homepageVal[5].caption }} />
              <a href={homepageVal[5].link}><button>{homepageVal[5].button_label}</button></a>
            </div>
          </div>
        </div>

        {/* konten 4 */}
        <div className='py-10 md:py-16 lg:py-20'>
          <h1 className='amiri-regular tracking-widest'>{homepageVal[6].title}</h1> 
          
          <div className='max-w-[54rem] m-auto px-5 amiri-regular lg:text-xl pt-6 pb-10'>
            <div dangerouslySetInnerHTML={{ __html: homepageVal[6].caption }} />
          </div>
          <a href={homepageVal[6].link}><button className='mb-10'>{homepageVal[6].button_label}</button></a>

          <Slider slideUrls={slides4} />

          {/*video embed can be edit & if autoplay add => &autoplay=1 */}
          <div className='w-full h-48 md:h-96 lg:h-[40rem] relative mt-20'>
            <iframe className='rounded-md' width="100%" height="100%" src={`${homepageVal[7].link}&rel=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>

        {/* konten 5 */}
        <div className='py-10 md:py-16 lg:py-20'>
          <div className='md:flex'>
            <div className='md:w-1/2 m-auto'>
              <h1 className='px-2 amiri-regular tracking-widest'>{homepageVal[8].title}</h1> 
              
              <div className='max-w-[54rem] m-auto px-5 amiri-regular lg:text-xl py-6'>
                <div dangerouslySetInnerHTML={{ __html: homepageVal[8].caption }} />
              </div>
              <a href={homepageVal[8].link}><button className='mb-10'>{homepageVal[8].button_label}</button></a>
            </div>
            <div className='pt-10 md:pt-0 md:w-1/2 m-auto'>
              <img className='mx-auto rounded-md border-[1px] drop-shadow-md' src={urlAPI+homepageVal[8].image_url} alt="" /> 
            </div>
          </div>

          {/*video embed can be edit & if autoplay add => &autoplay=1 */}
          <div className='w-full h-48 md:h-96 lg:h-[40rem] relative mt-20'>
            <iframe className='rounded-md' width="100%" height="100%" src={`${homepageVal[9].link}&rel=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>

        {/* konten 6 - Hampers */}
        <div className='py-10 md:py-16 lg:py-20'>
          <p className='alegreya-sans-light text-sm pb-1'>{homepageVal[10].subtitle}</p>
          <h1 className='amiri-regular tracking-widest'>{homepageVal[10].title}</h1>
          
          <div className='max-w-[54rem] m-auto px-5 amiri-regular lg:text-xl py-6'>
            {/* <div>Hadirkan Sentuhan Eksklusif dengan Batik Kustom</div> */}
            {/* <div className='pt-6 pb-10' dangerouslySetInnerHTML={{ __html: homepageVal[2].caption }} /> */}
          </div>
          <a href={homepageVal[10].link}><button className='mb-10'>{homepageVal[10].button_label}</button></a>

          <Slider slideUrls={slides6} />

        </div>
        
      </div>
    }
    </div>
  )
} 

export default Destinations