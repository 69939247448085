import React from 'react';
import Galeri from './Galeri';

const Gallery = () => {
    return (
        <div>
            <Galeri/>
        </div>
    )
}

export default Gallery