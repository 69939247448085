import React, { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import { FaCheckCircle } from 'react-icons/fa';
import { apiFunctions, postData } from '../../services/api.services';
import Modal from "../../components/commons/Modal"

const PartnerCustom = () => {
  const [partnershipVal, setPartnership] = useState([]);

  const [openModal, setOpenModal] = useState(false)
  const[newsPost, setNewsPost] = useState({
    company: '',
    qty:'',
    shipping_date:'',
    budget:'',
    name: '',
    email:'',
    phone:'',
    details:'',
    source: 'Google Search',
    promotional: '0',
  })

  useEffect(()=>{
    apiFunctions.partnership((data) => {
      // console.log(data.partnership_images);
      setPartnership(data.data);
    });
  }, [])

  const handleInput = (event) =>{
    const { name, value } = event.target;
    let filteredValue = value;

    if (name === 'phone' || name === 'qty' || name === 'budget') {
        filteredValue = value.replace(/[^0-9+]/g, ''); 
    }

    setNewsPost({
        ...newsPost,
        [name]: filteredValue
    });
  }
  const handleRadioChange = (event) => {
    const newValue = event.target.checked ? '1' : '0';
    setNewsPost({
      ...newsPost,
      promotional: newValue,
    });
  };
  const handlePost = async (event) =>{
    event.preventDefault();
    // console.log(newsPost, 'news ----1');

    try {
        await postData('custom', newsPost);
        setOpenModal(true);
    } catch (error) {
        console.error('Error submitting data:', error);
        alert('Submission failed. Please try again.');
    }
  }

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {partnershipVal.length >= 1 &&
      <div className='border-b-[1px]'>
        <div className='bg-slate-50 w-full'>
          <div className='max-w-[270px] md:max-w-[640px] mx-auto py-16 px-4 text-center'>
            <h1 className='amiri-regular'>Custom Hampers</h1>
            <div className='alegreya-sans-light py-5'>
              <p>Personalisasi batik kustom bersama BatikOrganik dan berikan kesan bermakna bagi perusahaan Anda. 
                BatikOrganik bukan sekadar penyedia pakaian batik berkualitas tinggi, 
                berkomitmen untuk mewujudkan visi dan nilai-nilai unik dari perusahaan anda.</p>
            </div>
            <form onSubmit={handlePost}>
              <div className="sm:col-span-4 alegreya-sans-regular py-5 md:max-w-[440px] m-auto">
                <div className='text-left'>
                  <p>Company</p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.company} type="text" name="company" id="company" autoComplete="company" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter your company" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Number of Boxes <span className='text-sm'>(Approximately how many gifts will you need)</span></p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.qty} type="text" name="qty" id="qty" autoComplete="qty" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter number of box" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Box Shipping Date</p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.shipping_date} type="date" name="shipping_date" id="shipping_date" autoComplete="shipping" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter number of box" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Total Budget</p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.budget} type="text" name="budget" id="budget" autoComplete="budget" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter total budget" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Contact Name</p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.name} type="text" name="name" id="name" autoComplete="name" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter contact name" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Email Address</p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.email} type="text" name="email" id="email" autoComplete="email" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter email address" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Phone Number</p>
                  <div className="border flex sm:max-w-md">
                    <input onChange={handleInput} value={newsPost.phone} type="text" name="phone" id="phone" autoComplete="phone" className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter phone number" />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Any other details or information you'd like us to know</p>
                  <div className="border flex sm:max-w-md">
                    <textarea onChange={handleInput} value={newsPost.details} name="details" id="details" cols="100" rows="5" className='block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6'></textarea>
                  </div>
                </div>
                <div className="text-left my-4">
                  <p className='pb-2'>How did you find us?</p>
                  <div className="relative h-10 min-w-[200px]">
                    <select
                      onChange={handleInput}
                      value={newsPost.source}
                      name="source"
                      className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50">
                      <option value="Google Search">Google Search</option>
                      <option value="Facebook/Instagram Ads">Facebook/Instagram Ads</option>
                      <option value="Friends/Colleagues">Friends/Colleagues</option>
                      <option value="Former Client">Former Client</option>
                      <option value="Other">Other</option>
                    </select>
                    <label
                      className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-[#6B7280] before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-[#6B7280] after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Select Option
                    </label>
                  </div>
                </div>

                <div className='text-left'>
                  <div className="inline-flex items-center">
                    <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="custom-style1">
                      <input 
                        type="checkbox"
                        id="promotional"
                        name="promotional"
                        checked={newsPost.promotional === '1'}
                        onChange={handleRadioChange}
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-gray-900/10 bg-gray-900/5 p-0 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-0"
                        />
                      <span
                        className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                          className="w-full h-full scale-105">
                          <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd"></path>
                        </svg>
                      </span>
                    </label>
                    <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="custom-style1">
                      <p className="block font-sans text-xs md:text-sm antialiased font-normal leading-relaxed text-blue-gray-400">
                        I Agree to receive promotional emails from BatikOrganik
                      </p>
                    </label>
                  </div>
                </div>

                
                <div className='pt-4'>
                  <button onClick={handlePost} className='px-6 text-sm'>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <div className="text-center w-56">
            <FaCheckCircle size={56} className="mx-auto text-green-500" />
            <div className="mx-auto my-4 w-48">
              <h3 className="text-lg font-black text-gray-800">Success</h3>
              <p className="text-sm text-gray-500">
                Your data has been added!
              </p>
            </div>
            <div className="flex gap-4">
              <button
                className="btn btn-light w-full rounded-lg"
                onClick={() => setOpenModal(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal>
      </div>
      }
    </div>
  );
};

export default PartnerCustom;
