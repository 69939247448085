import React, { useEffect, useState } from 'react'
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok,FaWhatsapp,
  FaYoutube
} from 'react-icons/fa';

import { apiFunctions } from '../../services/api.services';
import { PostContactList } from '../../components/layout/Navbar/PostContact';

const ContactInfo = () => {
  const [contactVal, setContact] = useState([]);
  

  useEffect(()=>{
    apiFunctions.contact((data) => {
      // console.log(data);
      setContact(data.data);
    });
  }, [])


  return (
    <div className='text-center'>
      <div className='w-full bg-slate-100'>
        <div className='max-w-[1240px] mx-auto py-24 px-4 text-center'>
          <h1 className='amiri-regular pb-10'>Contact Us</h1>
          <div className='amiri-regular text-xl text-center md:w-2/6 m-auto pt-5'>
            <div className='flex flex-wrap gap-8'>
              {contactVal.instagram !== "0" && (
                <div className='flex pb-5 hover:tracking-wider m-auto'>
                  <FaInstagram className='icon' />
                  <a href={contactVal.instagram} target='_blank' className='pl-5'>Instagram</a>
                </div>
              )}
              {contactVal.tiktok !== "0" && (
                <div className='flex pb-5 hover:tracking-wider m-auto'>
                  <FaTiktok className='icon' />
                  <a href={contactVal.tiktok} target='_blank' className='pl-5'>Tiktok</a>
                </div>
              )}
              {contactVal.twitter !== "0" && (
                <div className='flex pb-5 hover:tracking-wider m-auto'>
                  <FaTwitter className='icon' />
                  <a href={contactVal.twitter} target='_blank' className='pl-5'>Twitter</a>
                </div>
              )}
              {contactVal.facebook !== "0" && (
                <div className='flex pb-5 hover:tracking-wider m-auto'>
                  <FaFacebook className='icon' />
                  <a href={contactVal.facebook} target='_blank' className='pl-5'>Facebook</a>
                </div>
              )}
              {contactVal.youtube !== "0" && (
                <div className='flex pb-5 hover:tracking-wider m-auto'>
                  <FaYoutube className='icon' />
                  <a href={contactVal.youtube} target='_blank' className='pl-5'>Youtube</a>
                </div>
              )}
              {contactVal.whatsapp !== "0" && (
                <div className='flex pb-5 m-auto hover:tracking-wider'>
                  <FaWhatsapp className='icon' />
                  <a href={contactVal.whatsapp} target='_blank' className='pl-5'>Whatsapp</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <PostContactList/>

    </div>
  )
} 

export default ContactInfo