import React, {useState, useEffect} from 'react';

import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';

const News = () => {
  const [blogVal, setBlog] = useState([]);

  useEffect(()=>{
    apiFunctions.blog((data) => {
      // console.log(data);
      setBlog(data.data);
    });
  }, [])

  const renderTruncatedHTML = (htmlString, maxLength) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    let textContent = tempDiv.textContent || tempDiv.innerText || '';
    if (textContent.length > maxLength) {
      textContent = textContent.substr(0, maxLength) + '...';
    }
    tempDiv.innerHTML = textContent;
    return tempDiv.innerHTML;
  };
  const formatDate = (dateString) => {
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];
  
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const formattedDate = `${day} ${months[monthIndex]} ${year}`;
    return formattedDate;
  };

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {blogVal.length >= 1 &&
      <div className='pb-14 border-b-[1px]'>
        <div className='w-full h-screen relative'>
          <img
            className='w-full h-full object-cover'
            src={urlAPI+blogVal[blogVal.length - 1].image_url}
          />
          <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
          <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4 amiri-regular'>
            {/* <h1 className='font-normal pb-5'>Welcome to Our Blog!</h1> */}
            <h1 className='font-normal pb-5'>{blogVal[blogVal.length - 1].title}</h1>
            <div className='text-2xl py-5' dangerouslySetInnerHTML={{ __html: renderTruncatedHTML(blogVal[blogVal.length - 1].description, 100) }} />
            {/* <div className='text-2xl' dangerouslySetInnerHTML={{ __html: (blogVal[blogVal.length - 1].description) }} /> */}
            {/* <div className='text-2xl'>{truncateText(blogVal[blogVal.length - 1].description, 100)}</div> */}
            {/* <p className='text-2xl py-5'>{truncateText(blogVal[blogVal.length - 1].description, 100)}</p> */}
            <a href={`/blog/${blogVal.length - 1}`}><button className='w-fit mx-auto'>Read More</button></a>
          </div>
          
        </div>
        <div className='max-w-[1240px] mx-auto py-24 px-4 text-center'>
          <div className='alegreya-sans-light text-lg'>
            <h1 className='font-normal amiri-regular'>News</h1>
          </div>
        </div>
        <div>
          <div className='w-full relative md:flex h-auto flex-wrap'>
            {blogVal.slice(0, -1).map((item, index) => (
            <a href={`/blog/${index}`} className='md:w-2/6 h-full p-2'>
              <img
                className='w-full h-80 object-cover'
                src={urlAPI+item.image_url}
              />
              <div className='relative top-0 w-full h-full flex flex-col justify-center p-4 alegreya-sans-regular'>
                <p className='text-sm'>By {item.admin} . {formatDate(item.created_at)}</p>
                <p className='text-xl py-4'>{item.title}</p>
                <div dangerouslySetInnerHTML={{ __html: renderTruncatedHTML(item.description, 100) }} />
                {/* <p >Free international shipping for purchases made in the month of March only! Read the terms & conditions here.</p> */}
              </div>
            </a>
            ))}
          </div>
          
        </div>
        
      </div>
      }
    </div>
  );
};

export default News;
