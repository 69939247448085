import React, { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';

import { SliderBox } from '../../components/commons/Swiper';

const Partnership = () => {
  const [partnershipVal, setPartnership] = useState([]);
  const [partnershipLogo, setLogoPartnership] = useState([]);

  useEffect(()=>{
    apiFunctions.partnership((data) => {
      setPartnership(data.data);
      setLogoPartnership(data.partnership_images);
    });
  }, [])

  const slidesLogo = partnershipLogo.map((item) => item.image_url && (urlAPI + item.image_url)).filter((slide) => slide);

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {partnershipVal.length >= 1 &&
      <div className='border-b-[1px]'>
        <div className='md:w-full lg:w-9/12 md:grid lg:grid-cols-2 gap-5 m-auto py-16'>
          {partnershipVal.filter((item) => item.section_part === '2').map((item) => 
            <div className='w-72  md:w-96 lg:w-[25rem] mx-auto'>
                <div className='h-auto text-center alegreya-sans-regular'>
                  <h2 className='md:text-2xl lg:text-3xl'>{item.title}</h2>
                  <div className='my-5' dangerouslySetInnerHTML={{ __html: item.caption }} />
                </div>
                <img
                    src={urlAPI+item.image_url}
                    className='h-72 md:w-full md:h-auto object-cover mx-auto'
                />
                <div className='p-4 pb-8 text-center'>
                  <a href={item.link}><button>{item.button_label}</button></a>
                </div>
            </div>
          )}
        </div>

        <div className='mx-auto py-24 px-4 text-center bg-slate-50'>
          <div className='alegreya-sans-light text-lg max-w-[32rem] mx-auto'>
            <p className='text-sm pb-1'>{partnershipVal[5].subtitle}</p>
            <h2 className='amiri-regular tracking-widest'>{partnershipVal[5].title}</h2>
            <div className='py-10 text-xl text-justify md:max-w-6xl m-auto' >
              <div dangerouslySetInnerHTML={{ __html: partnershipVal[5].caption }} />
            </div>
            {/* <p className='py-10 text-xl text-justify md:max-w-6xl m-auto' dangerouslySetInnerHTML={{ __html: partnershipVal[0].caption }} /> */}
          </div>
        </div>

        <div className='text-center bg-black/10 py-16 pb-24'>
          <div className='amiri-regular pb-10'>
            <p className='text-8xl md:text-[10rem] bg-clip-text text-transparent bg-gradient-to-b from-[#000] to-[#fff]'>{partnershipVal[6].title}</p>
            <h1 className='font-normal text-black/70'>{partnershipVal[6].subtitle}</h1>
            {/* <p className='py-10 text-xl text-center md:max-w-6xl m-auto' dangerouslySetInnerHTML={{ __html: partnershipVal[2].caption }} /> */}
          </div>
          <div className='mx-auto px-10 swiper-logo'>
            <SliderBox slideUrls={slidesLogo} />
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Partnership;
