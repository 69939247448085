import React, { useEffect, useState } from 'react';

import { Swiper1, Swiper12, Swiper12_md, Swiper13, Swiper13_md, Swiper1_md, Swiper2} from '../../components/commons/Swiper';
import { useMediaQuery } from 'react-responsive';
import { Parallax } from 'react-parallax';
import { apiFunctions, urlAPI } from '../../services/api.services';

const GalleryReview = () => {
  const [galleryVal, setGallery] = useState([]);

  useEffect(()=>{
    apiFunctions.gallery((data) => {
      // console.log(data);
      setGallery(data.data);
    });
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {galleryVal.length >= 1 &&
      <div>
        <div className='w-full h-screen relative'>
          <img
            className='w-full h-full object-cover'
            src={urlAPI+galleryVal[0].image_url}
          />
          <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
          <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4 amiri-regular'>
            <h1 className='font-normal pb-5'>{galleryVal[0].title}</h1>
            <div>
              <div className='text-2xl' dangerouslySetInnerHTML={{ __html: galleryVal[0].caption }} />
            </div>
          </div>
          
        </div>
        <div className='max-w-[1240px] mx-auto py-24 px-4 text-center'>
          <div className='alegreya-sans-light text-lg'>
            <p>{galleryVal[2].title}</p>
            <h2 className='font-normal amiri-regular' dangerouslySetInnerHTML={{ __html: galleryVal[2].caption }} />
          </div>
        </div>
        <div>
          <div className='w-full relative md:flex'>
            {isMobile ? (
            <div className='col-span-6'>
              <Swiper1/>
              <div className='alegreya-sans-regular p-8 text-justify'>
                <p className='uppercase font-light'>WHAT THEY SAY</p>
                <div dangerouslySetInnerHTML={{ __html: galleryVal[1].caption }} />
              </div>
            </div>
            ) : (
            <div className='w-3/6'>
              <Swiper1_md/>
              <div className='alegreya-sans-regular p-8'>
                <p className='uppercase font-light'>WHAT THEY SAY</p>
                <div dangerouslySetInnerHTML={{ __html: galleryVal[1].caption }} />
              </div>
            </div>
            )}
            <div className='md:w-3/6 md:pr-8'>
              <img src={urlAPI+galleryVal[1].image_url} alt="" className='w-full md:h-[30rem] object-cover'/>
              <div className='flex absolute w-full md:w-3/6 md:h-[22rem] items-center justify-center bottom-40'>
                <a href={galleryVal[1].link}><button className='border-0'>{galleryVal[1].button_label}</button></a>
              </div>
            </div>
          </div>
          <div className='w-full relative md:flex p-8'>
            <div className='md:w-3/6'>
              <img src={urlAPI+galleryVal[6].image_url} alt="" className='md:h-[40rem] w-[40rem] object-cover'/>
            </div>
            <div className='md:w-3/6 amiri-regular my-auto pt-8 md:pt-0'>
              <h1 className='font-normal'>{galleryVal[6].title}</h1>
              <p className='py-10 text-xl' dangerouslySetInnerHTML={{ __html: galleryVal[6].caption }} />
              <a href={galleryVal[6].link}><button>{galleryVal[6].button_label}</button></a>
            </div>
          </div>
          <div className='py-16'>
            <Parallax className='relative h-screen bg-cover' bgImage={urlAPI+galleryVal[7].image_url} strength={200}>
              
            </Parallax>
          </div>
        </div>

        <div className='pt-8'>
          <div className='w-full relative md:flex pb-16'>
            {/* SWIPER PERLU BARU */}
            {isMobile ? (
            <div className='col-span-6'>
              <Swiper12/>
              <div className='alegreya-sans-regular p-8 text-justify'>
                <p className='uppercase font-light'>WHAT THEY SAY</p>
                <div dangerouslySetInnerHTML={{ __html: galleryVal[8].caption }} />
              </div>
            </div>
            ) : (
            <div className='w-3/6'>
              <Swiper12_md/>
              <div className='alegreya-sans-regular p-8'>
                <p className='uppercase font-light'>WHAT THEY SAY</p>
                <div dangerouslySetInnerHTML={{ __html: galleryVal[8].caption }} />
              </div>
            </div>
            )}
            <div className='md:w-3/6 md:pr-8'>
              <img src={urlAPI+galleryVal[8].image_url} alt="" className='w-full md:h-[30rem] object-cover'/>
              <div className='flex absolute w-full md:w-3/6 md:h-[22rem] items-center justify-center bottom-40'>
                <a href={galleryVal[8].link}><button className='border-0'>{galleryVal[8].button_label}</button></a>
              </div>
            </div>
          </div>
          <div className='w-full relative md:flex py-12 p-8 bg-neutral-100'>
            <div className='md:w-3/6 amiri-regular pt-20'>
              <h1 className='font-normal text-center py-7'>{galleryVal[12].title}</h1>
              <img src={urlAPI+galleryVal[12].image_url} alt="" className='md:h-[40rem] w-[42rem] object-cover'/>
            </div>
            <div className='md:w-3/6 amiri-regular pt-8 md:pt-0 text-center'>
              <Swiper2/>
              <p className='py-10 text-xl' dangerouslySetInnerHTML={{ __html: galleryVal[12].caption }} />
              <a href={galleryVal[12].link}><button>{galleryVal[12].button_label}</button></a>
            </div>
          </div>
          <div className='w-full relative md:flex py-16 md:py-24'>
            <div className='md:w-3/6 alegreya-sans-regular p-8 md:p-24 text-justify mx-auto'>
              <p className='uppercase font-light'>WHAT THEY SAY</p>
              <div className='font-light' dangerouslySetInnerHTML={{ __html: galleryVal[25].caption }} />
            </div>
            <div className='md:w-3/6'>
              <img src={urlAPI+galleryVal[25].image_url} alt="" className='w-80 md:w-[30rem] md:h-[35rem] mx-auto'/>
            </div>
          </div>
          <div className='w-full relative md:flex pt-8'>
            {isMobile ? (
            <div className='col-span-6'>
              {/* SWIPER PERLU BARU */}
              <Swiper13/>
              <div className='alegreya-sans-regular p-8 text-justify'>
                <p className='uppercase font-light'>WHAT THEY SAY</p>
                <div dangerouslySetInnerHTML={{ __html: galleryVal[26].caption }} />
              </div>
            </div>
            ) : (
            <div className='w-3/6'>
              <Swiper13_md/>
              <div className='alegreya-sans-regular p-8'>
                <p className='uppercase font-light'>WHAT THEY SAY</p>
                <div dangerouslySetInnerHTML={{ __html: galleryVal[26].caption }} />
              </div>
            </div>
            )}
            <div className='md:w-3/6 md:pr-8'>
              <img src={urlAPI+galleryVal[26].image_url} alt="" className='w-full md:h-[30rem] object-cover'/>
              <div className='flex absolute w-full md:w-3/6 md:h-[22rem] items-center justify-center bottom-40'>
                <button className='border-0'>{galleryVal[26].button_label}</button>
              </div>
            </div>
          </div>
          <div className='py-16'>
            <Parallax className='relative h-screen bg-cover' bgImage={urlAPI+galleryVal[30].image_url} strength={200}>
              
            </Parallax>
          </div>
        </div>

        <div className='pt-8'>
          <div className='w-full relative md:flex p-8'>
            <div className='md:w-3/6'>
              <img src={urlAPI+galleryVal[31].image_url} alt="" className='md:h-96 w-[40rem] object-cover'/>
            </div>
            <div className='md:w-3/6 amiri-regular my-auto md:my-0 pt-8 md:pt-0'>
              <h1 className='font-normal'>{galleryVal[31].title}</h1>
              <p className='py-3 text-xl' dangerouslySetInnerHTML={{ __html: galleryVal[31].caption }} />
              <a href={galleryVal[31].link}><button>{galleryVal[31].button_label}</button></a>
            </div>
          </div>
          <div className='w-full relative md:flex py-16 md:py-24'>
            <div className='md:w-3/6 alegreya-sans-regular p-8 md:p-24 text-justify mx-auto'>
              <p className='uppercase font-light'>WHAT THEY SAY</p>
              <div className='font-light' dangerouslySetInnerHTML={{ __html: galleryVal[32].caption }} />
            </div>
            <div className='md:w-3/6'>
              <img src={urlAPI+galleryVal[32].image_url} alt="" className='w-80 md:w-[30rem] md:h-[35rem] mx-auto'/>
            </div>
          </div>
          <div className='py-16'>
            <Parallax className='relative h-screen bg-cover' bgImage={urlAPI+galleryVal[33].image_url} strength={200}>
              <div className='flex absolute h-screen w-full items-center justify-center text-center'>
                <div className='text-white amiri-regular'>
                  <p className='text-2xl md:text-4xl mx-10 md:mx-24' dangerouslySetInnerHTML={{ __html: galleryVal[33].caption }} />
                </div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default GalleryReview;
