import React, { useState, useEffect } from 'react';
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs';
import {Swiper, SwiperSlide} from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination, Scrollbar, A11y, EffectCube, Autoplay, EffectCards, EffectCreative } from 'swiper/modules';
import { apiFunctions, urlAPI } from '../../services/api.services';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import phoneShape from '../../assets/Phone Shape.png'

// import {EffectCoverflow, Pagination, Navigation} from 'swiper';

const useGallery = () => {
  const [galleryVal, setGallery] = useState([]);

  useEffect(() => {
    apiFunctions.gallery((data) => {
      // console.log(data);
      setGallery(data.data);
    });
  }, []);

  return galleryVal;
};

const Swiper1 = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={
          {
            rotate:0,
            stretch:0,
            depth:100,
            modifier:2.5,
          }
        }
        pagination={{el:'.swiper-pagination', clickable:true}}
        navigation={{
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev',
          clickable:true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}

        className='swiper_container'
      >
        <SwiperSlide>
          <img src={urlAPI+galleryVal[3].image_url} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={urlAPI+galleryVal[4].image_url} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={urlAPI+galleryVal[5].image_url} alt="slide_image" />
        </SwiperSlide>
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      }
    </div>
  );
};

const Swiper1_md = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <div className='flex'>
        <img src={urlAPI+galleryVal[3].image_url} alt="" className='pr-1 w-60 h-80'/>
        <img src={urlAPI+galleryVal[4].image_url} alt="" className='pr-1 w-60 h-80'/>
        <img src={urlAPI+galleryVal[5].image_url} alt="" className='pr-1 w-60 h-80'/>
      </div>
      }
    </div>

  );
};
const Swiper12 = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={
          {
            rotate:0,
            stretch:0,
            depth:100,
            modifier:2.5,
          }
        }
        pagination={{el:'.swiper-pagination', clickable:true}}
        navigation={{
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev',
          clickable:true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}

        className='swiper_container'
      >
        <SwiperSlide>
          <img src={urlAPI+galleryVal[9].image_url} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={urlAPI+galleryVal[10].image_url} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={urlAPI+galleryVal[11].image_url} alt="slide_image" />
        </SwiperSlide>
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      }
    </div>
  );
};

const Swiper12_md = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <div className='flex'>
        <img src={urlAPI+galleryVal[9].image_url} alt="" className='pr-1 w-60 h-80'/>
        <img src={urlAPI+galleryVal[10].image_url} alt="" className='pr-1 w-60 h-80'/>
        <img src={urlAPI+galleryVal[11].image_url} alt="" className='pr-1 w-60 h-80'/>
      </div>
      }
    </div>

  );
};
const Swiper13 = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={
          {
            rotate:0,
            stretch:0,
            depth:100,
            modifier:2.5,
          }
        }
        pagination={{el:'.swiper-pagination', clickable:true}}
        navigation={{
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev',
          clickable:true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}

        className='swiper_container'
      >
        <SwiperSlide>
          <img src={urlAPI+galleryVal[27].image_url} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={urlAPI+galleryVal[28].image_url} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={urlAPI+galleryVal[29].image_url} alt="slide_image" />
        </SwiperSlide>
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      }
    </div>
  );
};

const Swiper13_md = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <div className='flex'>
        <img src={urlAPI+galleryVal[27].image_url} alt="" className='pr-1 w-60 h-80'/>
        <img src={urlAPI+galleryVal[28].image_url} alt="" className='pr-1 w-60 h-80'/>
        <img src={urlAPI+galleryVal[29].image_url} alt="" className='pr-1 w-60 h-80'/>
      </div>
      }
    </div>

  );
};

const Swiper2 = () => {
  const galleryVal = useGallery();

  return (
    <div>
      {galleryVal.length >= 1 &&
      <Swiper
        grabCursor={true}
        centeredSlides={false}
        loop={true}
        slidesPerView={'2'}
        coverflowEffect={
          {
            rotate:0,
            stretch:0,
            depth:100,
            modifier:2.5,
          }
        }
        pagination={{el:'.swiper-pagination', clickable:true}}
        navigation={{
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev',
          clickable:true,
        }}
        modules={[Pagination, Navigation]}

        className='swiper_container w-full'
      >
        {galleryVal.slice(13, 24).map((item, index) => (
            item.image_url &&
            <SwiperSlide key={index} className='m-auto'>
              <img src={urlAPI + item.image_url} alt={`slide_image_${index}`} />
            </SwiperSlide>
        ))}
        <div className="slider-controller">
          <div className="swiper-button-prev slider-arrow text-white"></div>
          <div className="text-white swiper-button-next slider-arrow "></div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      }
    </div>
  );
};

export const Slider = ({ slideUrls }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, Autoplay, EffectCreative]}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}

      effect={'creative'}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }}

      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {slideUrls.map((url, index) => (
        <SwiperSlide key={index}>
          <img src={url} alt={`Slide ${index}`} className="w-full h-full object-cover rounded-md" />
        </SwiperSlide>
      ))}
    </Swiper>
  )

}

export const SliderPopUp = ({ slideUrls }) => {
  return (
    <Swiper
    className='sliderPopUp'
      modules={[Navigation, Pagination, Scrollbar, EffectCreative]}
      // loop={true}

      effect={'creative'}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }}

      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {slideUrls.map((url, index) => (
        <SwiperSlide key={index}>
          <img src={url} alt={`Slide ${index}`} className="rounded" />
        </SwiperSlide>
      ))}
    </Swiper>
  )

}
export const SliderPopUpAll = ({ slideUrls }) => {
  return (
    <Swiper
    className='sliderPopUp'
      modules={[Navigation, Pagination, Scrollbar, EffectCreative]}
      // loop={true}

      effect={'creative'}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }}

      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {slideUrls.map((url, index) => (
        <SwiperSlide key={index}>
          <p className='amiri-regular max-w-56 lg:max-w-none'>{url.name}</p>
          <img src={url.image} alt={`Slide ${index}`} className="rounded" />
        </SwiperSlide>
      ))}
    </Swiper>
  )

}

export const SliderBox = ({ slideUrls }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar]}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}

      spaceBetween={10}
      slidesPerView={'auto'}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        640: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 60,
        },
        1024: {
          slidesPerView: 7,
          spaceBetween: 0,
        },
      }}
      
    >
      {slideUrls.map((url, index) => (
        <SwiperSlide key={index} className='p-4'>
          <div className='w-20 h-20 md:w-44 md:h-44 lg:w-32 lg:h-32 xl:w-44 xl:h-44 rounded-md drop-shadow-sm bg-white'>
            <img src={url} alt={`Slide ${index}`} className="p-2 w-20 h-20 md:w-44 md:h-44 lg:w-32 lg:h-32 xl:w-44 xl:h-44 object-contain m-auto" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )

}

export const SliderReview = ({ slideUrls }) => {
  return (
    <Swiper
      modules={[Navigation, Scrollbar, Autoplay]}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}

      spaceBetween={0}
      slidesPerView={'auto'}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      }}
      
    >
      {slideUrls.map((url, index) => (
        <SwiperSlide key={index} className='my-5'>
          <div className='w-full h-96 md:h-[28rem] lg:h-96 xl:h-[480px]'>
            <img src={url} alt={`Slide ${index}`} className="rounded-lg w-full h-full object-cover m-auto" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )

}

export const ReviewGallery = ({ slideUrls }) => {
  return (
    <Swiper
      modules={[Navigation, Scrollbar, Autoplay]}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}

      spaceBetween={0}
      slidesPerView={'auto'}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
      }}
      
    >
      {slideUrls.map((url, index) => (
        <SwiperSlide key={index} className='my-5'>
          <div className='w-auto h-48 md:h-auto bg-white mx-1 lg:mx-2 rounded-lg'>
            <img src={url} alt={`Slide ${index}`} className="rounded-lg w-auto h-full object-contain lg:object-cover m-auto content-center" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )

}

export const SliderMockup = ({ slideUrls }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, ]}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}

      spaceBetween={0}
      slidesPerView={'auto'}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      }}
      
    >
      {slideUrls.map((item, index) => (
        <SwiperSlide key={index} >
          <div className='w-full my-10'>
              <div className='my-8 md:mt-10 w-[350px] h-auto md:h-[170px] mx-auto'>
                <p className='text-lg amiri-bold pt-2 pb-4'>{item.title}</p>
                <p className='alegreya-sans-regular' dangerouslySetInnerHTML={{ __html: item.caption }}/>
              </div>
              {/* <div className='bg-white relative'>
                <img src={item.image.ImgExample} class="w-auto h-[431px] md:h-[441.5px] lg:h-[390px] xl:h-[423px] absolute left-[45px] md:left-[45px] lg:left-[41px] xl:left-[104px] bottom-[28px] z-[9999] object-cover" alt=""/>
                <img className='drop-shadow-[-1px_2px_7px_rgba(0,0,0,0.25)] xl:h-[520px] m-auto' src={phoneShape} alt="" />
              </div> */}
              <div class="relative mx-auto border-white bg-transparent border-x-[14px] border-t-[50px] rounded-t-[2.5rem] drop-shadow-[-2px_2px_7px_rgba(0,0,0,0.25)] h-auto w-[300px]">
                <div class="overflow-hidden w-auto h-auto bg-white">
                    <img src={urlAPI + item.image_url} class="w-auto h-auto" alt=""/>
                </div>
              </div>

              {/* <div class="relative mx-auto border-white bg-transparent border-[14px] rounded-t-[2.5rem] shadow-[-3px_-1px_25px_3px_rgba(0,0,0,0.25)] h-[500px] w-[300px]">
                <div class="h-[32px] w-[3px] bg-white absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                <div class="h-[46px] w-[3px] bg-white absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                <div class="h-[46px] w-[3px] bg-white absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                <div class="h-[64px] w-[3px] bg-white absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                <div class="rounded-t-[2rem] overflow-hidden w-[272px] h-[486px] bg-white">
                    <img src={item.image.ImgExample} class="w-[272px] h-[486px]" alt=""/>
                </div>
              </div> */}
              
            </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )

}

export {Swiper1, Swiper1_md, Swiper12, Swiper12_md, Swiper13, Swiper13_md, Swiper2};
