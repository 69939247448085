import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'; 

import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';
import Accordion from '../../components/commons/Accordion';

const AllCollection = () => {
  const [collectVal, setCollect] = useState([]);
  const [catVal, setCat] = useState([]);

  const location = useLocation();
  const { search, state } = location;
  const searchResult = state?.searchResult;

  // Use searchResult and search from the URL as needed
  // console.log("Search Result:", searchResult);
  // console.log("Search Term:", new URLSearchParams(search).get("search"));

  useEffect(()=>{
    apiFunctions.collection((data) => {
      setCollect(data.data);
    });
    apiFunctions.category((data) => {
      setCat(data.data);
    });
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 }); 

  return (
    <div>
      {collectVal.length >= 1 &&
      <div className='py-24 md:py-32 border-b-[1px]'>
        <div className='max-w-[1240px] mx-auto pb-4 px-4 text-center'>
          {/* {filteredCategory && ( */}
          <div className='alegreya-sans-light text-lg'>
            <h1 className='font-normal amiri-regular pb-4'>All Collection</h1>
            {/* <div className='md:max-w-[45rem] m-auto' dangerouslySetInnerHTML={{ __html: filteredCategory.description}} /> */}
          </div>
          {/* )} */}
        </div>
        <div>
          <div className='w-full relative md:flex h-auto flex-wrap justify-between p-6 pt-0 md:p-6'>
            <div>
                <Accordion
                    title='Category' 
                    answer=
                    {catVal.map((item, index) => (
                        <div className="px-7 py-4">
                            <a href={`/collection/${item.id}`}>{item.title}</a>
                        </div> 
                    ))}
                />
            </div>
            <div className='md:w-10/12 md:grid md:grid-cols-3 gap-5'>
                {collectVal.map((item) => (
                <div key={item.id} className=''>
                    {item.image_url && (
                    <img
                        src={`${urlAPI}${item.image_url}`}
                        alt={item.title}
                        className='w-96 h-96 md:h-[30rem] object-cover mx-auto'
                    />
                    )}
                    <div className='p-2 pt-0'>
                    <p className='text-lg alegreya-sans-regular text-center pt-2 pb-4'>{item.name}</p>
                    {/* Render sublinks or additional content here if needed */}
                    </div>
                </div>
                ))}
            </div>
          </div>
          
        </div>
        
      </div>
      } 
    </div>
  );
};

export default AllCollection;
