import React, { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { apiFunctions, urlAPI } from '../../services/api.services';

import petaBatik from '../../assets/PETA INDONESIA.jpg'
import petaBatikNoSpot from '../../assets/PETA INDONESIA tanpa titik.jpg'
import marker from '../../assets/marker.png'
import Modal from "../../components/commons/Modal"

import popModal1 from '../../assets/Filosofi Batik Etnik/Motif Etnik Serambi Aceh.jpg'
import popModal2 from '../../assets/Filosofi Batik Etnik/Motif Talawang.jpg'
import popModal3 from '../../assets/Filosofi Batik Etnik/Motif Etnik Kolintang.jpg'
import popModal4 from '../../assets/Filosofi Batik Etnik/Motif Etnik Tana Toraja.jpg'
import popModal5 from '../../assets/Filosofi Batik Etnik/Motif Etnik Wakatobi.jpg'
import popModal6 from '../../assets/Filosofi Batik Etnik/Motif Etnik Raja Ampat.jpg'
import popModal7 from '../../assets/Filosofi Batik Etnik/Motif Etnik Asmat.jpg'
import popModal8 from '../../assets/Filosofi Batik Etnik/Motif Siger Lampung.jpg'
import popModal9_1 from '../../assets/Filosofi Batik Etnik/Motif Etnik Andam.jpg'
import popModal9_2 from '../../assets/Filosofi Batik Etnik/Motif Etnik Pusaka.jpg'
import popModal10 from '../../assets/Filosofi Batik Etnik/Motif Kawung Kenari.jpg'
import popModal11_1 from '../../assets/Filosofi Batik Etnik/Motif Etnik Kentjana Padjadjaran.jpg'
import popModal11_2 from '../../assets/Filosofi Batik Etnik/Motif Prasasti.jpg'
import popModal12 from '../../assets/Filosofi Batik Etnik/Motif Aksara Sunda.jpg'
import popModal13 from '../../assets/Filosofi Batik Etnik/Motif Melasti.jpg'
import popModal14 from '../../assets/Filosofi Batik Etnik/Motif Etnik Pesona Bali.jpg'
import popModal15 from '../../assets/Filosofi Batik Etnik/Motif Etnik Masalembo.jpg'
import popModal16 from '../../assets/Filosofi Batik Etnik/TAKA MAKASSAR.jpg'
import popModal17 from '../../assets/Filosofi Batik Etnik/Motif Etnik Labuan Bajo.jpg'
import popModal18_1 from '../../assets/Filosofi Batik Etnik/Motif Etnik Sumba.jpg'
import popModal18_2 from '../../assets/Filosofi Batik Etnik/Motif Etnik Kanatar.jpg'

import { SliderPopUp, SliderPopUpAll } from '../../components/commons/Swiper';

const MapsBatik = () => {
  const [openModal1, setOpenModal1] = useState(false)
  const [openModal2, setOpenModal2] = useState(false)
  const [openModal3, setOpenModal3] = useState(false)
  const [openModal4, setOpenModal4] = useState(false)
  const [openModal5, setOpenModal5] = useState(false)
  const [openModal6, setOpenModal6] = useState(false)
  const [openModal7, setOpenModal7] = useState(false)
  const [openModal8, setOpenModal8] = useState(false)
  const [openModal9, setOpenModal9] = useState(false)
  const [openModal10, setOpenModal10] = useState(false)
  const [openModal11, setOpenModal11] = useState(false)
  const [openModal12, setOpenModal12] = useState(false)
  const [openModal13, setOpenModal13] = useState(false)
  const [openModal14, setOpenModal14] = useState(false)
  const [openModal15, setOpenModal15] = useState(false)
  const [openModal16, setOpenModal16] = useState(false)
  const [openModal17, setOpenModal17] = useState(false)
  const [openModal18, setOpenModal18] = useState(false)
  const [openAll, setOpenAll] = useState(false)

  const markers = [
    { id: 1, image: popModal1, name:'Aceh'},
    { id: 2, image: popModal2, name:'Kalimantan Tengah' },
    { id: 3, image: popModal3, name:'Sulawesi Utara' },
    { id: 4, image: popModal4, name:'Sulawesi Selatan' },
    { id: 5, image: popModal5, name: 'Sulawesi Tenggara, Kabupaten Wakatobi' },
    { id: 6, image: popModal6, name: 'Papua Barat, Kabupaten Raja Ampat' },
    { id: 7, image: popModal7, name: 'Papua' },
    { id: 8, image: popModal8, name: 'Lampung' },
    { id: 9, image: popModal9_1, name: 'Banten'},
    { id: 9, image: popModal9_2, name: 'Banten' },
    { id: 10, image: popModal10, name: 'Jawa Barat, Kota Bogor' },
    { id: 11, image: popModal11_1, name: 'Jawa Barat, Kecamatan Bogor Selatan, Kota Bogor' },
    { id: 11, image: popModal11_2, name: 'Jawa Barat, Kecamatan Bogor Selatan, Kota Bogor'},
    { id: 12, image: popModal12, name: 'Jawa Barat Kecamatan Kawali, Kabupaten Ciamis'},
    { id: 13, image: popModal13, name: 'Bali, Badung, Kecamatan Kuta Selatan' },
    { id: 14, image: popModal14, name: 'Bali'},
    { id: 15, image: popModal15, name: 'Perairan Tengah Laut Jawa'},
    { id: 16, image: popModal16, name: 'Nusa Tenggara Timur, Kabupaten Manggarai Barat, Labuan Bajo' },
    { id: 17, image: popModal17, name: 'Nusa Tenggara Timur, Kabupaten Manggarai Barat'},
    { id: 18, image: popModal18_1, name: 'Nusa Tenggara Timur, Pulau Sumba'},
    { id: 18, image: popModal18_2, name: 'Nusa Tenggara Timur, Pulau Sumba'},
  ];

  const slides9 = markers.filter((item) => item.id === 9).map((item) => item.image);
  const slides11 = markers.filter((item) => item.id === 11).map((item) => item.image);
  const slides18 = markers.filter((item) => item.id === 18).map((item) => item.image);
  const slidesAll = markers.map((item) => item);
  const slidesAllName = markers.map((item) => item.name);

  const isMobile = useMediaQuery({ maxWidth: 767 }); 
  const NotDesktop = useMediaQuery({maxWidth: 1024});
  const handleAllModal = () => {
    if (NotDesktop) {
      setOpenAll(true);
    }
  };
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);

  const handleSelectChange = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    setSelectedMarkerId(selectedId);
  };

  return (
    <div>
      <div>
        <div className='w-full h-96 md:h-screen relative'>
          <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center p-4 amiri-regular'>
            <p className='text-7xl md:text-8xl md:text-[10rem] bg-clip-text text-transparent bg-gradient-to-b from-[#ffc258] to-[#936d2b]'>Jejak Batik</p>
            <h1 className='font-normal'>Philosophy of Batik</h1>
            {/* <p className='py-10 text-xl text-center md:max-w-6xl m-auto' dangerouslySetInnerHTML={{ __html: partnershipVal[2].caption }} /> */}
          </div>
        </div>
        <div className="relative h-8 min-w-[200px] mb-4 ml-6">
          <select
            name="city"
            value={selectedMarkerId || ''}
            onChange={handleSelectChange}
            className="peer h-full w-[15rem] md:w-[22rem] rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50">
            {markers.map((item)=>(
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
            ))}
          </select>
          <label
            className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-[15rem] md:w-[22rem] select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-[#6B7280] before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-[#6B7280] after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Select City
          </label>
        </div>
        <Modal open={selectedMarkerId !== null} onClose={() => setSelectedMarkerId(null)}>
          {selectedMarkerId && (
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === selectedMarkerId)?.name}</p>
              <img src={markers.find(marker => marker.id === selectedMarkerId)?.image} alt="" className='rounded'/>
            </div>
          )}
        </Modal>
        
        <div className='relative w-full pb-[60%]'>
          {/* Render the map image */}
          {/* <img src={petaBatik} alt="Map" className='absolute w-auto h-auto m-auto right-0 left-0' onClick={handleAllModal}/> */}
          <img src={petaBatikNoSpot} alt="Map" className='absolute w-auto h-auto m-auto right-0 left-0' onClick={handleAllModal}/>
          <Modal open={openAll} onClose={() => setOpenAll(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <SliderPopUpAll slideUrls={slidesAll} />
            </div>
          </Modal>

          {/* Marker 1 */}
          <div className='tooltip absolute top-[24.5%] left-[3.9%] lg:top-[23.7%] lg:left-[5.4%]' onClick={setOpenModal1}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 1).name}</span>
          </div>
          <Modal open={openModal1} onClose={() => setOpenModal1(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 1).name}</p>
              <img src={popModal1} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 2 */}
          <div className='tooltip absolute top-[45.7%] left-[39.3%] lg:top-[44.2%] lg:left-[39.7%]' onClick={setOpenModal2}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 2).name}</span>
          </div>
          <Modal open={openModal2} onClose={() => setOpenModal2(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 2).name}</p>
              <img src={popModal2} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 3 */}
          <div className='tooltip absolute top-[37.5%] left-[63.5%] lg:top-[36.2%] lg:left-[63%]' onClick={setOpenModal3}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 3).name}</span>
          </div>
          <Modal open={openModal3} onClose={() => setOpenModal3(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 3).name}</p>
              <img src={popModal3} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 4 */}
          <div className='tooltip absolute top-[52.6%] left-[53.6%] lg:top-[50.8%] lg:left-[53.4%]' onClick={setOpenModal4}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 4).name}</span>
          </div>
          <Modal open={openModal4} onClose={() => setOpenModal4(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 4).name}</p>
              <img src={popModal4} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 5 */}
          <div className='tooltip absolute top-[61%] left-[62%] lg:top-[59%] lg:left-[61.6%]' onClick={setOpenModal5}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 5).name}</span>
          </div>
          <Modal open={openModal5} onClose={() => setOpenModal5(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 5).name}</p>
              <img src={popModal5} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 6 */}
          <div className='tooltip absolute top-[42.9%] left-[77.2%] lg:top-[41.4%] lg:left-[76.3%] overflow-hidden lg:overflow-visible' onClick={setOpenModal6}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 6).name}</span>
          </div>
          <Modal open={openModal6} onClose={() => setOpenModal6(false)}>
            <div className="w-[15rem] md:w-[23rem]">
            <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 6).name}</p>
              <img src={popModal6} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 7 */}
          <div className='tooltip absolute top-[54.2%] left-[91.8%] lg:top-[52.4%] lg:left-[90.4%] overflow-hidden lg:overflow-visible' onClick={setOpenModal7} >
            <img src={marker} className='w-[12%] md:w-[12%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 7).name}</span>
          </div>
          <Modal open={openModal7} onClose={() => setOpenModal7(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 7).name}</p>
              <img src={popModal7} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 8 */}
          <div className='tooltip absolute top-[58%] left-[21.3%] lg:top-[56.2%] lg:left-[22.3%]' onClick={setOpenModal8}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 8).name}</span>
          </div>
          <Modal open={openModal8} onClose={() => setOpenModal8(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 8).name}</p>
              <img src={popModal8} alt="" className='rounded'/>
            </div>
          </Modal>
          
          {/* Marker 9 */}
          <div className='tooltip absolute top-[64%] left-[25.9%] lg:top-[61.8%] lg:left-[26.7%]' onClick={setOpenModal9}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 9).name}</span>
          </div>
          <Modal open={openModal9} onClose={() => setOpenModal9(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 9).name}</p>
              <SliderPopUp slideUrls={slides9} />
            </div>
          </Modal>

          {/* Marker 11 */}
          <div className='tooltip absolute top-[66%] left-[26.8%] lg:top-[63.9%] lg:left-[27.5%]' onClick={setOpenModal11}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 11).name}</span>
          </div>
          <Modal open={openModal11} onClose={() => setOpenModal11(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 11).name}</p>
              <SliderPopUp slideUrls={slides11} />
            </div>
          </Modal>

          {/* Marker 10 */}
          <div className='tooltip absolute top-[65.1%] left-[26.5%] lg:top-[63%] lg:left-[27.3%]' onClick={setOpenModal10} >
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 10).name}</span>
          </div>
          <Modal open={openModal10} onClose={() => setOpenModal10(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 10).name}</p>
              <img src={popModal10} alt="" className='rounded'/>
            </div>
          </Modal>
          
          {/* Marker 12 */}
          <div className='tooltip absolute top-[65.7%] left-[28.8%] lg:top-[63.6%] lg:left-[29.6%]' onClick={setOpenModal12}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 12).name}</span>
          </div>
          <Modal open={openModal12} onClose={() => setOpenModal12(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 12).name}</p>
              <img src={popModal12} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 14 */}
          <div className='tooltip absolute top-[70.5%] left-[43.4%] lg:top-[68.3%] lg:left-[43.6%]' onClick={setOpenModal14}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 14).name}</span>
          </div>
          <Modal open={openModal14} onClose={() => setOpenModal14(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 14).name}</p>
              <img src={popModal14} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 13 */}
          <div className='tooltip absolute top-[71.6%] left-[43.1%] lg:top-[69.4%] lg:left-[43.3%]' onClick={setOpenModal13}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 13).name}</span>
          </div>
          <Modal open={openModal13} onClose={() => setOpenModal13(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 13).name}</p>
              <img src={popModal13} alt="" className='rounded'/>
            </div>
          </Modal>
          
          {/* Marker 15 */}
          <div className='tooltip absolute top-[64.3%] left-[49.8%] lg:top-[62.2%] lg:left-[49.8%]' onClick={setOpenModal15}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 15).name}</span>
          </div>
          <Modal open={openModal15} onClose={() => setOpenModal15(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 15).name}</p>
              <img src={popModal15} alt="" className='rounded'/>
            </div>
          </Modal>
          
          {/* Marker 16 */}
          <div className='tooltip absolute top-[71.5%] left-[52.9%] lg:top-[69.2%] lg:left-[52.8%]' onClick={setOpenModal16}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 16).name}</span>
          </div>
          <Modal open={openModal16} onClose={() => setOpenModal16(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 16).name}</p>
              <img src={popModal16} alt="" className='rounded'/>
            </div>
          </Modal>
          
          {/* Marker 17 */}
          <div className='tooltip absolute top-[70.6%] left-[53.7%] lg:top-[68.3%] lg:left-[53.6%]' onClick={setOpenModal17}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 17).name}</span>
          </div>
          <Modal open={openModal17} onClose={() => setOpenModal17(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 17).name}</p>
              <img src={popModal17} alt="" className='rounded'/>
            </div>
          </Modal>

          {/* Marker 18 */}
          <div className='tooltip absolute top-[75.4%] left-[53.7%] lg:top-[72.8%] lg:left-[53.6%]' onClick={setOpenModal18}>
            <img src={marker} className='w-[3.5%] md:w-[7.5%] lg:w-[14%] h-auto' alt="Marker"/>
            <span class="tooltiptext">{markers.find(marker => marker.id === 18).name}</span>
          </div>
          <Modal open={openModal18} onClose={() => setOpenModal18(false)}>
            <div className="w-[15rem] md:w-[23rem]">
              <p className='amiri-regular max-w-56 lg:max-w-none'>{markers.find(marker => marker.id === 18).name}</p>
              <SliderPopUp slideUrls={slides18} />
            </div>
          </Modal>

          {/* Add more markers as needed */}
        </div>
        
      </div>
    </div>
  );
};

export default MapsBatik;
