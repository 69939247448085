import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import { apiFunctions, urlAPI } from '../../services/api.services';

const BannerCTA = () => {
  const [homepageVal, setHomepage] = useState([]);

  useEffect(()=>{
    apiFunctions.homepage((data) => {
      // console.log(data.data);
      setHomepage(data.data);
    });
  }, [])

  return (
    <div>
      {homepageVal.length >= 1 &&
      <div className='w-full relative'>
        <div className='w-full relative'>
          <img
            className='w-full object-cover'
            src={urlAPI+homepageVal[11].image_url}
          />
          <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'>
            <div className='text-white p-5 md:p-10 lg:p-40 w-3/4 md:w-3/6'>
              <p className='text-lg md:text-4xl amiri-regular'>{homepageVal[11].title}</p>
              <p className='text-sm md:text-lg alegreya-sans-regular py-2 md:py-5'>{homepageVal[11].subtitle}</p>
              <a href={homepageVal[11].link}>
                <button className='bg-transparent hover:bg-transparent px-4 py-1 md:px-7 md:py-3'>{homepageVal[11].button_label}</button>
              </a>
            </div>
          </div>
        </div>
        
      </div>
      }
    </div>
  );
};

export default BannerCTA;
