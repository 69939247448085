import React, { useEffect, useState, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';

import { FaCheckCircle } from 'react-icons/fa';
import { FaAddressCard, FaBox, FaGift } from 'react-icons/fa6';
import { apiFunctions, postData, urlAPI } from '../../services/api.services';
import Modal from "../../components/commons/Modal"

import { Tabs } from "flowbite-react";
import {fetchImages,importAll} from '../../services/fetchImages';
import { MdMessage } from 'react-icons/md';
import Accordion from '../../components/commons/Accordion';
import { Link } from 'react-router-dom';

const PartnerBuild = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); 
  const [contactVal, setContact] = useState([]);
  const [categoryVal, setCatHampers] = useState([]);
  const [corporateVal, setCorporate] = useState([]);

  useEffect(()=>{
    apiFunctions.categoryHampers((data) => {
      setCatHampers(data.data);
    });
    apiFunctions.corporate((data) => {
      setCorporate(data.data);
    });

    apiFunctions.contact((data) => {
      setContact(data.data);
    });
  }, [])
  
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedGift, setSelectedGift] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleBoxSelect = (index) => {
    setSelectedBox(index);
    // console.log(index,'Box berhasil')
  };

  const handleGiftSelect = (index) => {
    setSelectedGift(index);
    // console.log(index,'Gift berhasil')
  };

  const handleCardSelect = (index) => {
    setSelectedCard(index);
    // console.log(index,'Card berhasil')
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const handleContinue = () => {
    const nextTab = activeTab + 1;

    if (nextTab < 4) {
      // If nextTab is within the valid range (0 to 3), switch to the next tab
      tabsRef.current?.setActiveTab(nextTab);
      setActiveTab(nextTab);
    } else {
      // If nextTab is 4 (last tab), perform final action (e.g., submit to WhatsApp link)
      submitToWhatsApp();
    }
  };
  
  const [toValue, setToValue] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");

  const submitToWhatsApp = () => {
    const messageContent = `Halo BatikOrganik, saya ingin membuat Hampers menggunakan '${selectedBox}', '${selectedGift}', dan '${selectedCard}' dengan isi pesan:\n` +
    `Dari: ${fromValue}\n` +
    `Kepada: ${toValue}\n` +
    `Deskripsi: ${descriptionValue}`;

    const encodedMessage = encodeURIComponent(messageContent);
    
    const whatsappLink = `${contactVal.whatsapp}&text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleCategorySelect = (categoryId) => {
    setSelectedCategoryId(categoryId === 'all' ? null : categoryId);
  };

  const filteredGifts = selectedCategoryId !== null
    ? corporateVal.filter((item) => item.section === 'gift' && item.category_id === selectedCategoryId)
    : corporateVal.filter((item) => item.section === 'gift');

  return (
    <div className='border-b-[1px]'>

      <div className='max-w-[1100px] mx-auto mt-10 mb-20'>

        <Tabs aria-label="Tabs with icons" style="underline" className='tabs-custom mx-auto' ref={tabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
          <Tabs.Item active={activeTab === 'Packaging'} title="Packaging" icon={FaBox} onClick={() => handleTabChange('Packaging')}>
            <div className='text-center amiri-regular py-2 pb-10'>
              <h1>Select your packaging box</h1>
              <p>Are you looking to create your own customized hampers and gifts?</p>
              <p>Then you are at the right spot, start by selecting your Box!</p>
            </div>
            <Tabs aria-label="Tabs with underline" style="underline" className='tabs-custom2 mx-auto'>
              <Tabs.Item active title="Small">
                <div className="select_pack grid md:grid-cols-3 gap-4 w-3/4 md:w-full max-w-screen-sm lg:max-w-screen-lg amiri-regular m-auto">
                  {corporateVal.filter((item) => item.section === 'packaging' && item.type === 'small').map((item,index)=>(
                    <div>
                      <input className="hidden" id={`radioSmallBox_${index}`} type="radio" name="radio"
                      onChange={() => handleBoxSelect(item.name)}
                      />
                      <label className="flex flex-col p-4 border-2 border-gray-200 cursor-pointer" htmlFor={`radioSmallBox_${index}`}>
                        <img src={urlAPI+item.image_url} alt="" className='w-56 h-56 md:w-44 md:h-44 lg:w-80 lg:h-80 object-cover mx-auto'/>
                        <span className="text-xl font-bold mt-2 text-center text-gray-400">{item.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </Tabs.Item>
              <Tabs.Item title="Regular">
                <div className="select_pack grid md:grid-cols-3 gap-4 w-3/4 md:w-full max-w-screen-sm lg:max-w-screen-lg amiri-regular m-auto">
                  {corporateVal.filter((item) => item.section === 'packaging' && item.type === 'regular').map((item,index)=>(
                    <div>
                      <input className="hidden" id={`regularBox_${index}`} type="radio" name="radio"
                      onChange={() => handleBoxSelect(item.name)}
                      />
                      <label className="flex flex-col p-4 border-2 border-gray-200 cursor-pointer" htmlFor={`regularBox_${index}`}>
                        <img src={urlAPI+item.image_url} alt="" className='w-56 h-56 md:w-44 md:h-44 lg:w-80 lg:h-80 object-cover mx-auto'/>
                        <span className="text-xl font-bold mt-2 text-center text-gray-400">{item.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </Tabs.Item>
            </Tabs>
          </Tabs.Item>

          <Tabs.Item active={activeTab === 'Gift'} title="Gift" icon={FaGift} onClick={() => handleTabChange('Gift')}>
            <div className='text-center amiri-regular py-2 pb-10'>
              <h1>Select your Gift</h1>
              <p>Continue to selecting your Gift!</p>
            </div>
            <div className='w-full relative md:flex h-auto flex-wrap justify-between p-6'>
              <div className='mb-5'>
                  <Accordion
                    title='Category' 
                    answer=
                        {
                        <div>
                          <div className="px-7 py-4">
                            <Link to={`/partnership/build/all`} onClick={() => handleCategorySelect('all')}>
                              All Category
                            </Link>
                          </div>
                          {categoryVal.filter((item) => item.section === 'gift').map((item) => (
                          <div className="px-7 py-4">
                            <Link to={`/partnership/build/${item.id}`} onClick={() => handleCategorySelect(item.id)}>
                              {item.title}
                            </Link>
                          </div>
                          ))}
                        </div> 
                        }
                  />
              </div>
              <div className="select_pack grid md:grid-cols-3 gap-4 w-3/4 md:w-full max-w-screen-sm lg:max-w-screen-lg amiri-regular m-auto">
                {filteredGifts.map((item,index)=>(
                  <div>
                    <input className="hidden" id={`radioGift_${index}`} type="radio" name="radio"
                    onChange={() => handleGiftSelect(item.name)}
                    />
                    <label className="flex flex-col p-4 border-2 border-gray-200 cursor-pointer" htmlFor={`radioGift_${index}`}>
                      <img src={urlAPI+item.image_url} alt="" className='w-56 h-56 md:w-44 md:h-44 lg:w-80 lg:h-80 object-cover mx-auto'/>
                      <span className="text-xl font-bold mt-2 text-center text-gray-400">{item.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Tabs.Item>

          <Tabs.Item active={activeTab === 'Card'} title="Card" icon={FaAddressCard} onClick={() => handleTabChange('Card')}>
            <div className='text-center amiri-regular py-2 pb-10'>
              <h1>Select your Card</h1>
              <p>Continue to selecting your Card!</p>
            </div>
            <div className="select_pack grid md:grid-cols-3 gap-4 w-3/4 md:w-full max-w-screen-sm lg:max-w-screen-lg amiri-regular m-auto">
              {corporateVal.filter((item) => item.section === 'card').map((item,index)=>(
                <div>
                  <input className="hidden" id={`radioCard_${index}`} type="radio" name="radio"
                  onChange={() => handleCardSelect(item.name)}
                  />
                  <label className="flex flex-col p-4 border-2 border-gray-200 cursor-pointer" htmlFor={`radioCard_${index}`}>
                    <img src={urlAPI+item.image_url} alt="" className='w-56 h-56 md:w-44 md:h-44 lg:w-80 lg:h-80 object-cover mx-auto'/>
                    <span className="text-xl font-bold mt-2 text-center text-gray-400">{item.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </Tabs.Item>
          <Tabs.Item title="Message" icon={MdMessage}>
            <div className='text-center amiri-regular py-2 pb-10'>
              <h1>Fill out the form</h1>
              <p>Please fill out the Form for your card!</p>
            </div>
            <form>
              <div className="sm:col-span-4 alegreya-sans-regular py-5 md:max-w-[440px] m-auto">
                <div className="text-left">
                  <p>To</p>
                  <div className="border flex sm:max-w-md">
                    <input
                      type="text"
                      name="to"
                      id="to"
                      autoComplete="off"
                      className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      placeholder="Enter the recipient"
                      value={toValue}
                      onChange={(e) => setToValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>From</p>
                  <div className="border flex sm:max-w-md">
                    <input
                      type="text"
                      name="from"
                      id="from"
                      autoComplete="off"
                      className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      placeholder="Enter the sender"
                      value={fromValue}
                      onChange={(e) => setFromValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-left my-4">
                  <p>Description</p>
                  <div className="border flex sm:max-w-md">
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="5"
                      className="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      placeholder="Enter the description"
                      value={descriptionValue}
                      onChange={(e) => setDescriptionValue(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </Tabs.Item>
        </Tabs>
        {/* Continue button */}
        <div className='text-right mt-10'>
          <button
            onClick={handleContinue}
            className='rounded-md py-3 px-6 cursor-pointer bg-black text-white'
          >
            {activeTab < 3 ? 'Continue' : 'Submit'}
          </button>
        </div>


      </div>
    </div>
  );
};

export default PartnerBuild;
