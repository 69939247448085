import React from 'react';
import Collection from './Collection';

const CollectPage = () => {
    return (
        <div>
            <Collection/>
        </div>
    )
}

export default CollectPage