import React from 'react';
import SinglePost from './SingleNews';

const SingleBlog = () => {
    return (
        <div>
            <SinglePost/>
        </div>
    )
}

export default SingleBlog