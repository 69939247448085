import React from 'react';
import PartnerHeader from './PartnerHeader';
import PartnerHampers from './PartnerHampers';

const PartnerSelectHampers = () => {
    return (
        <div>
            <PartnerHeader/>
            <PartnerHampers/>
        </div>
    )
}

export default PartnerSelectHampers