import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./NavLinks";
import {BsPerson} from 'react-icons/bs'; 
import { BiSearch } from 'react-icons/bi';
import { AiOutlineShop } from 'react-icons/ai';
import { apiFunctions, urlAPI, directFetchData } from '../../../services/api.services';

import Modal from "../../commons/Modal"
const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavLinksHovered, setIsNavLinksHovered] = useState(false);

  const [contactVal, setContact] = useState([]);

  useEffect(() => {
    apiFunctions.contact((data) => {
      // console.log(data);
      setContact(data.data);
    });

    const changeColor = () => {
      if (window.scrollY >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', changeColor);

    return () => {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);

  const handleNavLinksHover = (isHovered) => {
    setIsNavLinksHovered(isHovered);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const searchData = await directFetchData('collection', { search: searchTerm });
      // console.log('Filtered Data:', searchData.data);

      setOpenModal(false);
      navigate(`/collection?search=${encodeURIComponent(searchTerm)}`, {
        state: { searchResult: searchData.data },
      });
    } catch (error) {
      console.error('Failed to fetch filtered data:', error);
    }
  };

  return (
    <nav className={`fixed w-full h-auto z-20 top-[-15px] ${isScrolled || isNavLinksHovered ? 'bg-white' : ''}`} >
      <div className="flex items-center font-medium justify-between md:p-1">
        
        <ul className="lg:flex hidden uppercase items-center alegreya-sans-regular md:mt-auto md:mb-3">
          <li>
            <Link to="/gallery" className="px-3 inline-block z-20">
              Gallery
            </Link>
          </li>
          <NavLinks handleNavLinksHover={handleNavLinksHover}/>
          <li>
            <Link to="/blog" className="px-3 inline-block">
              Blog
            </Link>
          </li>
          {/* <li>
            <Link to="/contact" className="px-3 inline-block">
              Contact
            </Link>
          </li> */}
        </ul>
        <div className="z-50 lg:hidden w-[100px] md:w-[300px] flex md:justify-between p-2 md:p-0 mt-auto md:mb-5 mb-[10px]">
          <div className="text-3xl lg:hidden" onClick={() => setOpen(!open)}>
            <ion-icon
              class={`${open ? "relative left-[20rem] md:left-[38rem] top-1 md hydrated" : "static left-auto top-auto md hydrated"}`}
              name={open ? "close" : "menu"}
              // style={{ position: open ? "relative" : "static", left: open ? "20rem" : "auto", top: open ? "2" : "auto" }}
            ></ion-icon>
          </div>
        </div>
        <a href="/" className='pt-2 md:pt-0'><img src={urlAPI+contactVal.logo_img} alt="logo" className="md:cursor-pointer h-20 md:h-24 w-auto object-cover md:block " /></a>
        <div className="flex md:w-[300px] lg:w-[516px] justify-end md:p-0 p-2 mt-auto md:mb-3 mb-[15px]">
          <ul className='flex alegreya-sans-regular uppercase'>
            <li className='p-1 md:p-5'>
              <a href="/admin"><BsPerson size={20} /></a>
              {/* <a href="/login"><BsPerson size={20} /></a> */}
            </li>
            <li className='p-1 md:p-5' onClick={() => setOpenModal(true)}>
              <a><BiSearch size={20} /></a>
            </li>
            <li className='p-1 md:p-5'>
              <a href="https://batikorganik.id" target='blank'><AiOutlineShop size={20} /></a>
            </li>
          </ul>
        </div>
        {/* Mobile nav */}
        <ul
          className={`
        lg:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 pl-4
        duration-500 z-10 alegreya-sans-regular uppercase ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <a href="/"><img src={urlAPI+contactVal.logo_img} alt="" className='h-20 top-2 object-cover relative left-0'/></a>
          <li className='pl-3'>
            <a href="/gallery" className="pt-0 inline-block">
              Gallery
            </a>
          </li>
          <NavLinks />
          {/* <div className="py-5">
            <button className="bg-primary text-white  px-6 py-2 rounded-full">
              Get Started
            </button>
          </div> */}
        </ul>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="text-center w-72 md:w-96">
          <p>Search Our Collection!</p>
          <form onSubmit={handleSearch}>
            <div class="relative mx-auto text-gray-600">
              <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} class="border-2 border-gray-300 bg-white h-10 px-5 w-full mt-4 rounded-lg text-sm focus:outline-none"
                autoComplete="search" id="search" name="search" placeholder="Search"/>
              <button type="submit" class="absolute right-0 bottom-0 border-0 hover:bg-transparent">
                <BiSearch className='text-gray-600 h-4 w-4 fill-current'/>
              </button>
            </div>
          </form>
          
        </div>
      </Modal>
    </nav>
  );
};

export default Navbar;
