import axios from 'axios';

// const urlAPI = 'http://localhost:8000'
const urlAPI = 'https://batikorganik.co.id/admin'

// const baseURL = 'http://localhost:8000/api'; // Base URL for API requests
const baseURL = 'https://batikorganik.co.id/api'; // Base URL for API requests

// Function to make a GET request
const fetchData = async (endpoint, callback) => {
    try {
        const response = await axios.get(`${baseURL}/${endpoint}`);
        callback(response.data);
    } catch (error) {
        console.log(error);
    }
};

const directFetchData = async (endpoint, params) => {
    try {
      const response = await axios.get(`${baseURL}/${endpoint}`, { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
};
  

// const directFetchData = async (endpoint, data) => {
//     try {
//         const response = await axios.get(`${baseURL}/${endpoint}`, data);
//         return response.data;
//         // console.log(response);
//     } catch (error) {
//         console.log(error);
//     }
//     // try {
//     //     const response = await axios.get(`${baseURL}/${endpoint}`);
//     //     return response.data;
//     // } catch (error) {
//     //     return error.message;
//     // }
// };

// Function to make a POST request
const postData = async (endpoint, data) => {
    try {
        const response = await axios.post(`${baseURL}/${endpoint}`, data);
    } catch (error) {
        console.log(error);
    }
};

// Define endpoints for different API requests
const endpoints = {
    homepage: 'home',
    contact: 'contact',
    gallery: 'gallery',
    blog: 'blog',
    faq: 'faq',
    journey: 'journey',
    aboutBatik: 'about',
    partnership: 'partnership',
    collection: 'collection',
    category: 'category',
    newsletter: 'newsletter',
    store: 'store',
    corporate: 'corporate',
    categoryHampers:'category-hampers',
    campaign:'campaign',
    testimonial:'testimonial',
    uniforms:'uniforms',
};

// Generate functions dynamically for each endpoint
const apiFunctions = {};
for (const key in endpoints) {
    if (endpoints.hasOwnProperty(key)) {
        const endpoint = endpoints[key];
        apiFunctions[key] = (callback) => fetchData(endpoint, callback);
    }
}

// Export API functions and baseURL
export { apiFunctions, urlAPI, postData, directFetchData };