import React from 'react';
import PartnerHeader from './PartnerHeader';
import PartnerCustom from './PartnerCustom';

const PartnerForm = () => {
    return (
        <div>
            <PartnerHeader/>
            <PartnerCustom/>
        </div>
    )
}

export default PartnerForm