import { useEffect, useState } from "react";
import { apiFunctions } from "../../services/api.services";

function NotFound() {
  const [contactVal, setContact] = useState([]);

  useEffect(()=>{
    apiFunctions.contact((data) => {
      setContact(data.data);
    });
  }, [])

  return (
    <>
    <div class="h-screen w-screen bg-gray-100 flex items-center justify-center">
        <div class="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
            <div class="max-w-md text-center md:text-left">
                <div class="text-5xl font-bold mb-4">404</div>
                <p class="text-2xl md:text-3xl font-light mb-4">Sorry, we couldn't find this page.</p>
                <p class="mb-8">But don't worry, you can find plenty of other things on our homepage.</p>
                <a href="/" class="px-4 py-3 text-sm font-medium leading-5 shadow text-white bg-black border-0 rounded-lg focus:outline-none hover:bg-gray-800">
                    Back to Homepage
                </a>
            </div>
        </div>
    </div>
    </>
  );
}

export default NotFound;
