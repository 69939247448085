import React, { useState } from 'react'
import {FaCheckCircle} from 'react-icons/fa';
import { postData } from '../../../services/api.services';
import Modal from '../../commons/Modal';

export const PostContactList = () => {
    const [openModal, setOpenModal] = useState(false)
    const[newsPost, setNewsPost] = useState({
      name: '',
      wa_number: ''
    })
    const handleInput = (event) =>{
      const { name, value } = event.target;
      let filteredValue = value;
      if (name === 'wa_number') {
          filteredValue = value.replace(/[^0-9+]/g, ''); // Allow only numbers and '+'
      }
      setNewsPost({
          ...newsPost,
          [name]: filteredValue
      });
    }
    const handlePost = async (event) =>{
      event.preventDefault();
      // console.log(newsPost, 'news ----1');
      await postData('newsletter', newsPost);
      setOpenModal(true);
      // console.log('Data posted successfully!');
    }
  
    return (
    <>
      <div className='bg-black w-full text-white'>
        <div className='max-w-[270px] md:max-w-[640px] mx-auto py-16 px-4 text-center'>
          <h1 className='amiri-regular'>Coming Soon to a Location Near You</h1>
          <div className='alegreya-sans-light py-5'>
            <p>Drop us your whatsapp number to stay up to date on our latest pop up and boutique locations.</p>
            <p>We'll share other updates and exclusive offers, too.</p>
          </div>
          <form onSubmit={handlePost}>
            <div class="sm:col-span-4 alegreya-sans-regular py-5">
              <div>
                <div class="mx-auto flex sm:max-w-md">
                  <input onChange={handleInput} value={newsPost.name} type="text" name="name" id="name" autoComplete="name" class="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter first name" />
                </div>
              </div>
              <div class="my-7">
                <div class="mx-auto flex sm:max-w-md">
                  <input onChange={handleInput} value={newsPost.wa_number} type="text" name="wa_number" id="wa_number" autoComplete="wa_number" class="block flex-1 border-0 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="Enter whatsapp number" />
                </div>
              </div>
              <button onClick={handlePost} className='px-6 text-sm'>Add Me to The List</button>
            </div>
          </form>
        </div>
      </div>
  
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="text-center w-56">
          <FaCheckCircle size={56} className="mx-auto text-green-500" />
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-gray-800">Success</h3>
            <p className="text-sm text-gray-500">
              Your data has been added!
            </p>
          </div>
          <div className="flex gap-4">
            <button
              className="btn btn-light w-full rounded-lg"
              onClick={() => setOpenModal(false)}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
    )
}